<template>
  <v-row>
    <!-- <v-card-text> -->
    <v-file-input
      v-model="fileValue"
      truncate-length="30"
      accept="image/*"
      :placeholder="placeHolder"
      v-on:change="onUserChanging"
      :rules="rules"
      :multiple="multiple"
      capture="environment"
    >
      <template #label v-if="required">
        <span class="red-error"><strong>* </strong></span>{{ label }}
      </template></v-file-input
    >
    <!-- </v-card-text> -->
    <!-- <v-card-text> -->
    <p v-if="errorMsg.length > 0" class="red-error">{{ errorMsg }}</p>
    <v-img :src="imgBase64" ref="uploadedPic" />
    <!-- </v-card-text> -->
  </v-row>
</template>
<script lang="ts">
//   show-size="false" chips

import { CreateEmptyFile } from "@/models/question-section/question";
import { StoreMutation } from "@/store/store-mapping";
import { ConsoleLog, IsNullOrEmpty, Text } from "@/utility";
import Vue from "vue";

import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    value: {},
    label: String,
    rules: Array<any>,
    required: Boolean,
    placeHolder: { type: String, default: "" },
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    initalImgBase64: String,
  },
  data() {
    return {
      showUpload: false,
      fileValue: {},
      imgBase64: "",
      errorMsg: "",
      multiple: false,
    };
  },
  computed: {},
  methods: {
    onChanging(value: any) {
      ConsoleLog({ "formFile.onChanging": value });
      this.$emit("input", value);
      //   this.$emit("userChange", value);
      //        v-on:input="onChanging"
    },
    async onUserChanging(value: any) {
      try {
        this.errorMsg = "";
        ConsoleLog({ "formFile.onUserChanging": value });
        if (IsNullOrEmpty(value) || value.name === "") {
          this.imgBase64 = "";
          // return;
        }
        this.$store.commit(StoreMutation.StartLoading, null, { root: true });
        await this.showPicture();
        //   this.$emit("userChange", value, (this as any).$refs.uploadedPic.src);
      } catch (error) {
        this.stopLoading();
        console.log("FormFile.onUserChanging.error", error);
      }
    },

    getPartString(strValue: string) {
      return IsNullOrEmpty(strValue) ? "" : strValue.substring(0, 1);
    },

    async showPicture() {
      const self = this as any;
      if (self.fileValue == null) {
        ConsoleLog(["formFile.showPicture.fileValue is null"]);
        self.$refs.uploadedPic.height = 0;
        self.$refs.uploadedPic.width =0
        self.$emit("userChange", self.fileValue, "");
        self.stopLoading();
        return;
      }
      if (self.fileValue.type.match(/image.*/)) {
        ConsoleLog("An image has been loaded");
        let reader = new FileReader();
        reader.onload = async function (readerEvent) {
          try {
            let image = new Image();
            image.onload = async function (ev) {
              try {
                ConsoleLog("start of loadImage");
                // // // Resize the image
                // // let canvas = document.createElement("canvas"),
                // //   max_size = 700, //800 is not good in firefox
                // //   width = image.width,
                // //   height = image.height;
                // // ConsoleLog({
                // //   max_size: max_size,
                // //   width: image.width,
                // //   height: image.height,
                // // });

                // // if (width > height) {
                // //   if (width > max_size) {
                // //     height *= max_size / width;
                // //     width = max_size;
                // //   }
                // // } else {
                // //   if (height > max_size) {
                // //     width *= max_size / height;
                // //     height = max_size;
                // //   }
                // // }
                // // canvas.width = width;
                // // canvas.height = height;
                // // ConsoleLog({
                // //   "canvas.width": canvas.width,
                // //   "canvas.height": canvas.height,
                // // });
                // // var ctx = canvas.getContext("2d");
                // // ctx?.drawImage(image, 0, 0, width, height);
                // // let dataUrl = canvas.toDataURL("image/jpeg");
                // // ConsoleLog({ toDataURL: self.getPartString(dataUrl) });
                // // const dataSize = new Blob([dataUrl]).size;
                // // ConsoleLog({ dataSize: dataSize });
                // // if (dataSize > 262144) {
                // //   //file size is out of the limit
                // //   ConsoleLog("file size is out of the limit");
                // //   self.clearFile();
                // //   return;
                // // }
                const zipResult = await self.zipImage(
                  image,
                  image.width,
                  image.height,
                  1000
                );
                if (!zipResult.good) {
                  //file size is out of the limit
                  ConsoleLog("file size is out of the limit after unzip");
                  self.clearFile();
                  return;
                }
                self.imgBase64 = zipResult.data;
                self.$emit("userChange", self.fileValue, zipResult.data);
                self.stopLoading();
                ConsoleLog("end of loadImage");
              } catch (error) {
                console.log(" image.onload.error", error);
                self.stopLoading();
              }
            };
            if (
              readerEvent.target !== null &&
              readerEvent.target.result !== null
            ) {
              ConsoleLog(
                "readerEvent.target !== null && readerEvent.target.result !== null"
              );
              const loadResult = readerEvent.target.result.toString();
              image.src = loadResult;
              ConsoleLog({ loadResult: self.getPartString(loadResult) });
            }
          } catch (error) {
            console.log("reader.onload.error", error);
            self.stopLoading();
          }
        };
        reader.readAsDataURL(self.fileValue);

        console.log("reader completed");
      }
    },
    clearFile() {
      this.fileValue = CreateEmptyFile();
      this.errorMsg = Text("ml_114677", "Picture size is out of limit");
      this.stopLoading();
    },
    stopLoading() {
      ConsoleLog("stopLoading");
      this.$store.commit(StoreMutation.EndLoading, null, { root: true });
    },
    loadImage(image: any) {
      ConsoleLog("start of loadImage");
      let self = this as any;
      // Resize the image
      let canvas = document.createElement("canvas"),
        max_size = 500, //800 is not good in firefox
        width = image.width,
        height = image.height;
      ConsoleLog({
        max_size: max_size,
        width: image.width,
        height: image.height,
      });

      if (width > height) {
        if (width > max_size) {
          height *= max_size / width;
          width = max_size;
        }
      } else {
        if (height > max_size) {
          width *= max_size / height;
          height = max_size;
        }
      }
      canvas.width = width;
      canvas.height = height;
      ConsoleLog({
        "canvas.width": canvas.width,
        "canvas.height": canvas.height,
      });
      var ctx = canvas.getContext("2d");
      ctx?.drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL("image/jpeg");
      ConsoleLog({ toDataURL: self.getPartString(dataUrl) });
      const dataSize = new Blob([dataUrl]).size;
      ConsoleLog({ dataSize: dataSize });
      if (dataSize > 262144) {
        //file size is out of the limit
        ConsoleLog("file size is out of the limit");
      }
      self.imgBase64 = dataUrl;
      self.$emit("userChange", self.fileValue, self.getPartString(dataUrl));
      ConsoleLog("end of loadImage");
      self.stopLoading();
    },
    async zipImage(
      image: any,
      imgWidth: number,
      imgHeight: number,
      maxSize: number
    ) {
      ConsoleLog({
        zipImage: image,
        width: imgWidth,
        height: imgHeight,
        maxSize: maxSize,
      });
      if (maxSize <= 0) {
        return { data: "", good: false };
      }
      const self = this as any;
      // Resize the image
      let canvas = document.createElement("canvas"),
        max_size = maxSize, //800 is not good in firefox
        width = imgWidth,
        height = imgHeight;

      if (width > height) {
        if (width > max_size) {
          height *= max_size / width;
          width = max_size;
        }
      } else {
        if (height > max_size) {
          width *= max_size / height;
          height = max_size;
        }
      }
      canvas.width = width;
      canvas.height = height;
      // ConsoleLog({
      //   "canvas.width": canvas.width,
      //   "canvas.height": canvas.height,
      // });
      var ctx = canvas.getContext("2d");
      ctx?.drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL("image/jpeg");
      // ConsoleLog({ toDataURL: self.getPartString(dataUrl) });
      const dataSize = new Blob([dataUrl]).size;
      ConsoleLog({
        dataSize: dataSize,
        "canvas.width": width,
        "canvas.height": height,
      });
      if (dataSize > 262144) {
        //file size is out of the limit
        ConsoleLog("file size is out of the limit ,need to unzip again.");
        return await self.zipImage(image, imgWidth, imgHeight, maxSize - 100);
      } else {
        ConsoleLog(["file size is godd", maxSize]);
        return { data: dataUrl, good: true };
      }
    },
  },
  mounted(): void {
    const self = this as any;
    if (self.value) {
      self.fileValue = self.value;
    }
    if (self.initalImgBase64) {
      self.imgBase64 = self.initalImgBase64;
    }
    ConsoleLog(["FormFile.fileValue", self.fileValue]);
  },
});
</script>
