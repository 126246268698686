import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecordQuestionSummaryRegular = _resolveComponent("RecordQuestionSummaryRegular")!
  const _component_RecordQuestionSummaryAdditional = _resolveComponent("RecordQuestionSummaryAdditional")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (_ctx.recordQuestionSummaryData.containDefect)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.recordQuestionSummaryData.regularQuestionCollection, (questionRegular, questionRegularIndex) => {
                    return (_openBlock(), _createBlock(_component_RecordQuestionSummaryRegular, {
                      key: questionRegularIndex,
                      "record-question-summary-regular-data": questionRegular
                    }, null, 8, ["record-question-summary-regular-data"]))
                  }), 128))
                : _createCommentVNode("", true),
              (_ctx.recordQuestionSummaryData.additionalQuestionContainDefect)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.recordQuestionSummaryData.additionalQuestionCollection, (questionAdditional, questionAdditionalIndex) => {
                    return (_openBlock(), _createBlock(_component_RecordQuestionSummaryAdditional, {
                      key: questionAdditionalIndex + 1000,
                      "record-question-summary-additional-data": questionAdditional
                    }, null, 8, ["record-question-summary-additional-data"]))
                  }), 128))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512), [
        [_vShow, 
        _ctx.recordQuestionSummaryData.containDefect ||
        _ctx.recordQuestionSummaryData.additionalQuestionContainDefect
      ]
      ])
    ]),
    _: 1
  }))
}