import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_row, { class: "margin-0" }, {
      default: _withCtx(() => [
        (_ctx.required)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.hardStop ? 'back-ground-light-grey color-white' : '')
            }, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "red-error" }, [
                _createElementVNode("strong", null, "* ")
              ], -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, { class: "margin-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "margin-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: !_ctx.showQuestionIcon && !_ctx.showEditIcon ? 12 : '',
              class: "padding-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_radio_group, {
                  modelValue: _ctx.selectedValue,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
                    _ctx.onChanging
                  ],
                  inline: "",
                  rules: _ctx.rules,
                  readonly: _ctx.readonly,
                  class: "margin-0"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                      return (_openBlock(), _createBlock(_component_v_radio, {
                        key: item.itemValue,
                        label: item.itemText,
                        value: item.itemValue,
                        class: _normalizeClass(_ctx.cssAnswer(item.itemValue, _ctx.selectedValue))
                      }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", {
                            class: _normalizeClass(_ctx.cssLabel(item.itemValue, _ctx.selectedValue))
                          }, _toDisplayString(item.itemText), 3)
                        ]),
                        _: 2
                      }, 1032, ["label", "value", "class"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "rules", "onUpdate:modelValue", "readonly"])
              ]),
              _: 1
            }, 8, ["cols"]),
            _withDirectives(_createVNode(_component_v_col, {
              cols: _ctx.showEditIcon ? 1 : 2,
              class: "padding-0 alignMiddle"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_v_icon, {
                  onClick: _ctx.questionCick,
                  color: "darken-1",
                  icon: "mdi-map-marker-question"
                }, null, 8, ["onClick"]), [
                  [_vShow, _ctx.showQuestionIcon]
                ])
              ]),
              _: 1
            }, 8, ["cols"]), [
              [_vShow, _ctx.showQuestionIcon]
            ]),
            _withDirectives(_createVNode(_component_v_col, {
              cols: _ctx.showQuestionIcon ? 1 : 2,
              class: "padding-0 alignMiddle"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_v_icon, {
                  onClick: _ctx.editCick,
                  icon: "mdi-pencil",
                  color: "darken-1"
                }, null, 8, ["onClick"]), [
                  [_vShow, _ctx.showEditIcon]
                ])
              ]),
              _: 1
            }, 8, ["cols"]), [
              [_vShow, _ctx.showEditIcon]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}