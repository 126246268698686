import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"10px"} }
const _hoisted_2 = { class: "red-error" }
const _hoisted_3 = {
  key: 0,
  class: "red-error"
}
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_form_radio_group = _resolveComponent("form-radio-group")!
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_autocomplete = _resolveComponent("form-autocomplete")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_qr_scaner = _resolveComponent("qr-scaner")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_form_select = _resolveComponent("form-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_time_picker = _resolveComponent("v-time-picker")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[46] || (_cache[46] = [
                      _createTextVNode("Trip Info")
                    ])),
                    _: 1
                  })), [
                    [_directive_t, 'ml_114601']
                  ]),
                  (_ctx.IsLocalMode())
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        color: "primary",
                        onClick: _ctx.onNext
                      }, {
                        default: _withCtx(() => _cache[47] || (_cache[47] = [
                          _createTextVNode(" Next ")
                        ])),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_form, {
                    ref: "form",
                    modelValue: _ctx.valid,
                    "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.valid) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_form_radio_group, {
                            modelValue: _ctx.tripInfo.tripType,
                            "onUpdate:modelValue": [
                              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tripInfo.tripType) = $event)),
                              _cache[1] || (_cache[1] = (newValue) => _ctx.updateTripType(newValue))
                            ],
                            rules: _ctx.rules.tripType,
                            label: _ctx.Text('ml_114897', 'Trip Type'),
                            required: "",
                            input: _ctx.tripInfo.tripType,
                            items: _ctx.tripInfo.tripTypeItems
                          }, null, 8, ["modelValue", "rules", "label", "input", "items"]),
                          _withDirectives(_createVNode(_component_form_input, {
                            modelValue: _ctx.tripInfo.lbsTripId,
                            "onUpdate:modelValue": [
                              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tripInfo.lbsTripId) = $event)),
                              _cache[3] || (_cache[3] = (newValue) => _ctx.updateLbTripIdInfo(newValue))
                            ],
                            rules: _ctx.rules.lbsTripIdRule,
                            label: _ctx.Text('ml_114625', 'LBShell Trip ID'),
                            required: _ctx.showLbTripIdInput && (!_ctx.showOtherTripIdInput),
                            mask: _ctx.STR_TRIP_ID_MASK_LB,
                            placeholder: _ctx.STR_TRIP_ID_PLACE_HOLDER_LB
                          }, null, 8, ["modelValue", "rules", "label", "required", "mask", "placeholder"]), [
                            [_vShow, _ctx.showLbTripIdInput && (!_ctx.showOtherTripIdInput)]
                          ]),
                          _withDirectives(_createVNode(_component_form_input, {
                            modelValue: _ctx.tripInfo.pgTripId,
                            "onUpdate:modelValue": [
                              _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tripInfo.pgTripId) = $event)),
                              _cache[5] || (_cache[5] = (newValue) => _ctx.updateTripIdInfo(newValue))
                            ],
                            rules: _ctx.rules.pgTripIdRule,
                            label: _ctx.Text('ml_114902', 'PG Trip ID'),
                            required: (!_ctx.showLbTripIdInput) && (!_ctx.showOtherTripIdInput),
                            mask: _ctx.STR_TRIP_ID_MASK_PG,
                            placeholder: _ctx.STR_TRIP_ID_PLACE_HOLDER_PG
                          }, null, 8, ["modelValue", "rules", "label", "required", "mask", "placeholder"]), [
                            [_vShow, (!_ctx.showLbTripIdInput) && (!_ctx.showOtherTripIdInput)]
                          ]),
                          _createVNode(_component_form_radio_group, {
                            modelValue: _ctx.tripInfo.prePost,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tripInfo.prePost) = $event)),
                            rules: _ctx.rules.pre,
                            label: _ctx.Text('ml_114627', 'Pre/Post'),
                            required: "",
                            input: _ctx.tripInfo.prePost,
                            items: _ctx.tripInfo.prePostItems,
                            onUserChange: _cache[7] || (_cache[7] = ($event: any) => {_ctx.updateTripInfo(false, 'prePost');})
                          }, null, 8, ["modelValue", "rules", "label", "input", "items"]),
                          _createElementVNode("div", _hoisted_1, [
                            _withDirectives((_openBlock(), _createBlock(_component_form_autocomplete, {
                              items: _ctx.nearbyPlant,
                              modelValue: _ctx.tripInfo.customPlantId,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.tripInfo.customPlantId) = $event)),
                              label: _ctx.Text('ml_116553', 'Arrival DT'),
                              required: _ctx.showLbPlant,
                              itemText: "plantIdName",
                              itemValue: "plantId",
                              userFilter: _ctx.onUserFilter,
                              key: _ctx.tripInfo.customPlantId,
                              onUserChange: _cache[9] || (_cache[9] = (newValue) => _ctx.onPlantChange(newValue, _ctx.tripInfo)),
                              rules: _ctx.rules.customPlant
                            }, null, 8, ["items", "modelValue", "label", "required", "userFilter", "rules"])), [
                              [_vShow, _ctx.showLbPlant]
                            ]),
                            _withDirectives(_createVNode(_component_form_autocomplete, {
                              items: _ctx.nearbyPlant,
                              modelValue: _ctx.tripInfo.customPlantId,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.tripInfo.customPlantId) = $event)),
                              label: _ctx.showDT,
                              required: _ctx.showPgPlant,
                              itemText: "plantIdName",
                              itemValue: "plantId",
                              userFilter: _ctx.onUserFilter,
                              onUserChange: _cache[11] || (_cache[11] = (newValue) => _ctx.onPlantChange(newValue, _ctx.tripInfo)),
                              rules: _ctx.rules.customPlant
                            }, null, 8, ["items", "modelValue", "label", "required", "userFilter", "rules"]), [
                              [_vShow, _ctx.showPgPlant]
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.locationMsg), 1),
                          _createVNode(_component_form_input, {
                            modelValue: _ctx.tripInfo.driver1Name,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.tripInfo.driver1Name) = $event)),
                            rules: _ctx.rules.driver1,
                            label: _ctx.Text('ml_114629', 'Driver'),
                            readonly: true,
                            required: ""
                          }, null, 8, ["modelValue", "rules", "label"]),
                          _createVNode(_component_v_card_actions, { class: "v-card__actions_padding_left0 v-card__actions_padding_bottom_0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, null, {
                                    default: _withCtx(() => [
                                      (_ctx.tripInfo.isTractorThirdParty)
                                        ? (_openBlock(), _createBlock(_component_form_input, {
                                            key: 0,
                                            modelValue: _ctx.tripInfo.tractor,
                                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.tripInfo.tractor) = $event)),
                                            rules: _ctx.rules.tractor,
                                            onUserChange: _ctx.updateTripInfo,
                                            label: _ctx.Text('ml_114631', 'Tractor/Rigid/Truck'),
                                            required: ""
                                          }, null, 8, ["modelValue", "rules", "onUserChange", "label"]))
                                        : _createCommentVNode("", true),
                                      (!_ctx.tripInfo.isTractorThirdParty)
                                        ? (_openBlock(), _createBlock(_component_form_autocomplete, {
                                            key: 1,
                                            items: _ctx.vehicles(_ctx.SapVehcileCategory.Tractor),
                                            modelValue: _ctx.tripInfo.tractor,
                                            "onUpdate:modelValue": [
                                              _cache[14] || (_cache[14] = ($event: any) => ((_ctx.tripInfo.tractor) = $event)),
                                              _cache[15] || (_cache[15] = 
                    (newValue) =>
                      _ctx.onVehicleChange(
                        newValue,
                        _ctx.tripInfo,
                        _ctx.SapVehcileCategory.Tractor
                      )
                  )
                                            ],
                                            label: _ctx.Text('ml_114631', 'Tractor/Rigid/Truck'),
                                            required: "",
                                            itemText: "vehicleId",
                                            itemValue: "vehicleId",
                                            userFilter: 
                    (item, queryText, itemText) =>
                      _ctx.onUserVehicleFilter(
                        item,
                        queryText,
                        itemText,
                        _ctx.SapVehcileCategory.Tractor
                      )
                  ,
                                            rules: _ctx.rules.tractor
                                          }, null, 8, ["items", "modelValue", "label", "userFilter", "rules"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "5",
                                    class: "v-checkbox_padding_right_0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_checkbox, {
                                        class: "v-checkbox_margin_top_0",
                                        modelValue: _ctx.tripInfo.isTractorThirdParty,
                                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.tripInfo.isTractorThirdParty) = $event)),
                                        label: _ctx.Text('ml_116414', '3rd Party'),
                                        onChange: _cache[17] || (_cache[17] = (newValue) => _ctx.onTractorThirdPartyStatusChange(newValue,_ctx.tripInfo))
                                      }, null, 8, ["modelValue", "label"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "3",
                                    class: "qr-scaner_padding_left_0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_qr_scaner, {
                                        formId: "formIdTractor",
                                        initialValue: _ctx.tripInfo.tractor,
                                        labelText: _ctx.Text('ml_114631', 'Tractor/Rigid/Truck'),
                                        onHandleChange: _ctx.handleTractorChange
                                      }, null, 8, ["initialValue", "labelText", "onHandleChange"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_actions, { class: "pb-5 v-card__actions_padding_top_0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_spacer),
                                  _createVNode(_component_v_col, { cols: "10" }, {
                                    default: _withCtx(() => [
                                      (_ctx.tripInfo.isTractorThirdParty)
                                        ? (_openBlock(), _createBlock(_component_form_select, {
                                            key: 0,
                                            items: _ctx.getTractorCategoryItems(),
                                            modelValue: _ctx.tripInfo.tractor3rdPartyVehType,
                                            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.tripInfo.tractor3rdPartyVehType) = $event)),
                                            label: _ctx.Text('ml_116415', 'Vehicle Type'),
                                            required: "",
                                            rules: _ctx.rules.vehicleType
                                          }, null, 8, ["items", "modelValue", "label", "rules"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_actions, { class: "v-card__actions_padding_left0 v-card__actions_padding_bottom_0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, null, {
                                    default: _withCtx(() => [
                                      (_ctx.tripInfo.isChassisThirdParty)
                                        ? (_openBlock(), _createBlock(_component_form_input, {
                                            key: 0,
                                            modelValue: _ctx.tripInfo.chassis,
                                            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.tripInfo.chassis) = $event)),
                                            rules: _ctx.rules.chassis,
                                            onUserChange: _ctx.updateTripInfo,
                                            label: _ctx.Text('ml_114633', 'Chassis'),
                                            required: ""
                                          }, null, 8, ["modelValue", "rules", "onUserChange", "label"]))
                                        : _createCommentVNode("", true),
                                      (!_ctx.tripInfo.isChassisThirdParty)
                                        ? (_openBlock(), _createBlock(_component_form_autocomplete, {
                                            key: 1,
                                            showAsLabel: _ctx.showChassisAutoCompleteAsLabel(),
                                            items: _ctx.vehicles(_ctx.SapVehcileCategory.Chassis),
                                            modelValue: _ctx.tripInfo.chassis,
                                            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.tripInfo.chassis) = $event)),
                                            label: _ctx.Text('ml_114633', 'Chassis'),
                                            itemText: "vehicleId",
                                            itemValue: "vehicleId",
                                            userFilter: 
                    (item, queryText, itemText) =>
                      _ctx.onUserVehicleFilter(
                        item,
                        queryText,
                        itemText,
                        _ctx.SapVehcileCategory.Chassis
                      )
                  ,
                                            onUserChange: _cache[21] || (_cache[21] = 
                    (newValue) =>
                      _ctx.onVehicleChange(
                        newValue,
                        _ctx.tripInfo,
                        _ctx.SapVehcileCategory.Chassis
                      )
                  )
                                          }, null, 8, ["showAsLabel", "items", "modelValue", "label", "userFilter"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "5",
                                    class: "v-checkbox_padding_right_0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_checkbox, {
                                        class: "v-checkbox_margin_top_0",
                                        modelValue: _ctx.tripInfo.isChassisThirdParty,
                                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.tripInfo.isChassisThirdParty) = $event)),
                                        label: _ctx.Text('ml_116414', '3rd Party'),
                                        onChange: _cache[23] || (_cache[23] = (newValue) => _ctx.onChassisThirdPartyStatusChange(newValue,_ctx.tripInfo))
                                      }, null, 8, ["modelValue", "label"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "3",
                                    class: "qr-scaner_padding_left_0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_qr_scaner, {
                                        formId: "formIdChassis",
                                        initialValue: _ctx.tripInfo.chassis,
                                        onHandleChange: _ctx.handleChassisChange,
                                        labelText: _ctx.Text('ml_114633', 'Chassis')
                                      }, null, 8, ["initialValue", "onHandleChange", "labelText"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_actions, { class: "pb-5 v-card__actions_padding_top_0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_spacer),
                                  _createVNode(_component_v_col, { cols: "10" }, {
                                    default: _withCtx(() => [
                                      (_ctx.tripInfo.isChassisThirdParty)
                                        ? (_openBlock(), _createBlock(_component_form_select, {
                                            key: 0,
                                            items: _ctx.getChassisCategoryItems(),
                                            modelValue: _ctx.tripInfo.chassis3rdPartyVehType,
                                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.tripInfo.chassis3rdPartyVehType) = $event)),
                                            label: _ctx.Text('ml_116415', 'Vehicle Type'),
                                            required: "",
                                            rules: _ctx.rules.chassisVehicleType
                                          }, null, 8, ["items", "modelValue", "label", "rules"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_actions, { class: "v-card__actions_padding_left0 v-card__actions_padding_bottom_0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, null, {
                                    default: _withCtx(() => [
                                      (_ctx.tripInfo.isVesselThirdParty)
                                        ? (_openBlock(), _createBlock(_component_form_input, {
                                            key: 0,
                                            modelValue: _ctx.tripInfo.vessel,
                                            "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.tripInfo.vessel) = $event)),
                                            rules: _ctx.rules.vessel,
                                            onUserChange: _ctx.updateTripInfo,
                                            label: _ctx.Text('ml_114634', 'Vessel'),
                                            required: ""
                                          }, null, 8, ["modelValue", "rules", "onUserChange", "label"]))
                                        : _createCommentVNode("", true),
                                      (!_ctx.tripInfo.isVesselThirdParty)
                                        ? (_openBlock(), _createBlock(_component_form_autocomplete, {
                                            key: 1,
                                            showAsLabel: _ctx.showVesselAutoCompleteAsLabel(),
                                            items: _ctx.vehicles(_ctx.SapVehcileCategory.Vessel),
                                            modelValue: _ctx.tripInfo.vessel,
                                            "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.tripInfo.vessel) = $event)),
                                            label: _ctx.Text('ml_114634', 'Vessel'),
                                            itemText: "vesselId",
                                            itemValue: "vesselId",
                                            userFilter: 
                    (item, queryText, itemText) =>
                      _ctx.onUserVesselFilter(
                        item,
                        queryText,
                        itemText,
                        _ctx.SapVehcileCategory.Vessel
                      )
                  ,
                                            onUserChange: _cache[27] || (_cache[27] = 
                    (newValue) =>
                      _ctx.onVehicleChange(
                        newValue,
                        _ctx.tripInfo,
                        _ctx.SapVehcileCategory.Vessel
                      )
                  )
                                          }, null, 8, ["showAsLabel", "items", "modelValue", "label", "userFilter"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "5",
                                    class: "v-checkbox_padding_right_0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_checkbox, {
                                        class: "v-checkbox_margin_top_0",
                                        modelValue: _ctx.tripInfo.isVesselThirdParty,
                                        "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.tripInfo.isVesselThirdParty) = $event)),
                                        label: _ctx.Text('ml_116414', '3rd Party'),
                                        onChange: _cache[29] || (_cache[29] = (newValue) => _ctx.onVesselThirdPartyStatusChange(newValue,_ctx.tripInfo))
                                      }, null, 8, ["modelValue", "label"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "3",
                                    class: "qr-scaner_padding_left_0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_qr_scaner, {
                                        formId: "formIdVessel",
                                        initialValue: _ctx.tripInfo.vessel,
                                        onHandleChange: _ctx.handleVesselChange,
                                        labelText: _ctx.Text('ml_114634', 'Vessel')
                                      }, null, 8, ["initialValue", "onHandleChange", "labelText"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_actions, { class: "pb-5 v-card__actions_padding_top_0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_spacer),
                                  _createVNode(_component_v_col, { cols: "10" }, {
                                    default: _withCtx(() => [
                                      (_ctx.tripInfo.isVesselThirdParty)
                                        ? (_openBlock(), _createBlock(_component_form_select, {
                                            key: 0,
                                            items: _ctx.getVesselCategoryItems(),
                                            modelValue: _ctx.tripInfo.vessel3rdPartyVehType,
                                            "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.tripInfo.vessel3rdPartyVehType) = $event)),
                                            label: _ctx.Text('ml_116415', 'Vehicle Type'),
                                            required: "",
                                            rules: _ctx.rules.vesselVehicleType
                                          }, null, 8, ["items", "modelValue", "label", "rules"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_form_input, {
                            modelValue: _ctx.tripInfo.odometer,
                            "onUpdate:modelValue": [
                              _cache[31] || (_cache[31] = ($event: any) => ((_ctx.tripInfo.odometer) = $event)),
                              _cache[32] || (_cache[32] = ($event: any) => (_ctx.updateTripInfo(false, 'odometer')))
                            ],
                            rules: _ctx.rules.odometer,
                            label: _ctx.Text('ml_114635', 'Odometer'),
                            required: "",
                            type: this.numberType
                          }, null, 8, ["modelValue", "rules", "label", "type"]),
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_menu, null, {
                                activator: _withCtx(({ props }) => [
                                  _createVNode(_component_v_text_field, _mergeProps({
                                    modelValue: _ctx.tripInfo.date,
                                    "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.tripInfo.date) = $event)),
                                    "prepend-icon": "mdi-calendar"
                                  }, props, {
                                    label: _ctx.Text('ml_114636', 'Date'),
                                    rules: _ctx.rules.date,
                                    variant: "underlined",
                                    readonly: ""
                                  }), null, 16, ["modelValue", "label", "rules"])
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_v_date_picker, {
                                    modelValue: _ctx.getTripInfoDateObject,
                                    "onUpdate:modelValue": [
                                      _cache[34] || (_cache[34] = ($event: any) => ((_ctx.getTripInfoDateObject) = $event)),
                                      _ctx.updateDate
                                    ],
                                    max: _ctx.maxDate()
                                  }, null, 8, ["modelValue", "max", "onUpdate:modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.tripInfo.time,
                                "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.tripInfo.time) = $event)),
                                active: _ctx.modal2,
                                focused: _ctx.modal2,
                                label: _ctx.Text('ml_114637', 'Time'),
                                rules: _ctx.rules.time,
                                variant: "underlined",
                                readonly: "",
                                "prepend-icon": "mdi-clock-time-four-outline"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_dialog, {
                                    modelValue: _ctx.modal2,
                                    "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.modal2) = $event)),
                                    activator: "parent",
                                    width: "auto"
                                  }, {
                                    default: _withCtx(() => [
                                      (_ctx.modal2)
                                        ? (_openBlock(), _createBlock(_component_v_time_picker, {
                                            key: 0,
                                            "onUpdate:modelValue": [
                                              _ctx.updateTime,
                                              _cache[35] || (_cache[35] = ($event: any) => ((_ctx.tripInfo.time) = $event))
                                            ],
                                            format: "ampm",
                                            title: "",
                                            "ampm-in-title": "",
                                            max: _ctx.maxTime(),
                                            rules: _ctx.rules.time,
                                            modelValue: _ctx.tripInfo.time
                                          }, null, 8, ["onUpdate:modelValue", "max", "rules", "modelValue"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"])
                                ]),
                                _: 1
                              }, 8, ["modelValue", "active", "focused", "label", "rules"])
                            ]),
                            _: 1
                          }),
                          (_ctx.errorMsg.length > 0)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.errorMsg), 1))
                            : _createCommentVNode("", true),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                                color: "primary",
                                onClick: _cache[38] || (_cache[38] = ($event: any) => (_ctx.GoHome(_ctx.$router)))
                              }, {
                                default: _withCtx(() => _cache[48] || (_cache[48] = [
                                  _createTextVNode(" Home ")
                                ])),
                                _: 1
                              })), [
                                [_directive_t, 'ml_114649']
                              ]),
                              _createVNode(_component_v_spacer),
                              _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                                color: "primary",
                                onClick: _cache[39] || (_cache[39] = ($event: any) => (_ctx.onNext())),
                                disabled: !_ctx.valid
                              }, {
                                default: _withCtx(() => _cache[49] || (_cache[49] = [
                                  _createTextVNode(" Next ")
                                ])),
                                _: 1
                              }, 8, ["disabled"])), [
                                [_directive_t, 'ml_114639']
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_Modal, {
        isVisible: _ctx.showPrePostModal,
        onClose: _cache[42] || (_cache[42] = ($event: any) => (_ctx.showPrePostModal = false))
      }, {
        header: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("h3", null, _cache[50] || (_cache[50] = [
            _createTextVNode("Warning")
          ]))), [
            [_directive_t, 'ml_151131']
          ])
        ]),
        body: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.modalMessage), 1)
        ]),
        footer: _withCtx(() => [
          (_ctx.modalButtons.toLowerCase() === 'yes_no')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                  variant: "outlined",
                  class: "me-2",
                  block: "",
                  onClick: _ctx.revertSelection
                }, {
                  default: _withCtx(() => _cache[51] || (_cache[51] = [
                    _createTextVNode(" No ")
                  ])),
                  _: 1
                }, 8, ["onClick"])), [
                  [_directive_t, 'ml_151133']
                ]),
                _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                  color: "primary",
                  block: "",
                  onClick: _cache[41] || (_cache[41] = ($event: any) => (_ctx.showPrePostModal = false))
                }, {
                  default: _withCtx(() => _cache[52] || (_cache[52] = [
                    _createTextVNode(" Yes ")
                  ])),
                  _: 1
                })), [
                  [_directive_t, 'ml_151132']
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["isVisible"]),
      _createVNode(_component_Modal, {
        isVisible: _ctx.showA_DT_Modal,
        onClose: _cache[44] || (_cache[44] = ($event: any) => (_ctx.showA_DT_Modal = false))
      }, {
        header: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("h3", null, _cache[53] || (_cache[53] = [
            _createTextVNode("Warning")
          ]))), [
            [_directive_t, 'ml_151131']
          ])
        ]),
        body: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.modalA_DT_Message), 1)
        ]),
        footer: _withCtx(() => [
          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
            variant: "outlined",
            class: "me-2",
            block: "",
            onClick: _ctx.revertArrivalDTSelection
          }, {
            default: _withCtx(() => _cache[54] || (_cache[54] = [
              _createTextVNode(" No ")
            ])),
            _: 1
          }, 8, ["onClick"])), [
            [_directive_t, 'ml_151133']
          ]),
          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
            color: "primary",
            block: "",
            onClick: _cache[43] || (_cache[43] = ($event: any) => (_ctx.showA_DT_Modal = false))
          }, {
            default: _withCtx(() => _cache[55] || (_cache[55] = [
              _createTextVNode(" Yes ")
            ])),
            _: 1
          })), [
            [_directive_t, 'ml_151132']
          ])
        ]),
        _: 1
      }, 8, ["isVisible"]),
      _createVNode(_component_Modal, {
        isVisible: _ctx.showNotificationModal,
        onClose: _cache[45] || (_cache[45] = ($event: any) => (_ctx.showNotificationModal = false))
      }, {
        body: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.modalNotificationMessage), 1)
        ]),
        _: 1
      }, 8, ["isVisible"])
    ]),
    _: 1
  }))
}