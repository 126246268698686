<template>
  <!-- <v-container v-if="recordQuestionSummaryRegularItemData.notificationIsNeeded"> -->
  <v-row v-if="recordQuestionSummaryRegularItemData.notificationIsNeeded">
    <v-card-subtitle>{{ recordQuestionSummaryRegularItemData.desc }}</v-card-subtitle>
    <v-card-text>
      {{ recordQuestionSummaryRegularItemData.issueDesc }}
    </v-card-text>
    <template :key="index"
      v-for="(
        attachment, index
      ) in recordQuestionSummaryRegularItemData.attachments.filter(
        (item) => !IsNullOrEmpty(item.attachmentBase64)
      )"
    >
      <v-img        
        v-bind:src="AddBase64Head(attachment.attachmentBase64)"
        :alt="'attchment for' + recordQuestionSummaryRegularItemData.desc"
        :title="'attchment for' + recordQuestionSummaryRegularItemData.desc"
        :contain="true"
      ></v-img>
      <!--<hr :key="index + 200" />-->
      <hr />
    </template>
  </v-row>
  <!-- </v-container> -->
</template>
<script lang="ts">
import Vue from "vue";
// in full builds helpers are exposed as Vuex.mapState
import { mapState } from "vuex";
import { AddBase64Head, GoBack, RemoveBase64Head } from "@/utility/ui-utility";
// import RecordQuestionHead from './RecordQuestionHead.vue'
// import RecordQuestionRegular from './RecordQuestionRegular.vue'
// import RecordQuestionAdditional from './RecordQuestionAdditional.vue'
import { ConsoleLog, IsNullOrEmpty } from "@/utility";
// import HomePage from "../components/HomePage.vue";

import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    // RecordQuestionHead,
    // RecordQuestionRegular,
    // RecordQuestionAdditional,
    // HomePage,
  },
  data() {
    return {};
  },
  props: {
    recordQuestionSummaryRegularItemData: {
      type: Object,
    },
  },
  computed: {},
  methods: {
    IsNullOrEmpty,
    AddBase64Head,
    ConsoleLog,
    submitOrder() {},
  },
  mounted() {
    // ConsoleLog([
    //   "img src",
    //   AddBase64Head(this.recordQuestionSummaryRegularItemData.attachmentBase64),
    // ]);
  },
});
</script>

<style></style>
