<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title>{{ this.question.desc }}</v-card-title>
            <v-card-text>
              <v-card-title
                v-t="'ml_114651'"
                v-if="!IsNullOrEmpty(question.instruction)"
                >Instruction</v-card-title
              >
              <p v-if="!IsNullOrEmpty(question.instruction)">
                {{ this.question.instruction }}
              </p>
              <form-textarea
                v-model="question.issueDesc"
                :rules="rules.issueDesc"
                :label="Text('ml_114652', 'Description')"
                required
                rows="3"
              ></form-textarea>
            </v-card-text>

            <p v-if="whetherHidePictureUpload">
                {{ Text('ml_116413', "No picture needed for 3rd party defect") }}
            </p>
            <v-card-text v-if="!whetherHidePictureUpload">
              <form-file
                v-model="question.attachments[0].attachment"
                :label="Text('ml_114653', 'Attachment')"
                :initalImgBase64="
                  AddBase64Head(question.attachments[0].attachmentBase64)
                "
                @userChange="
                  (file, picBase64) => uploadAttachment(file, picBase64, 0)
                "
              >
              </form-file>
            </v-card-text>
            <v-card-text v-if="!whetherHidePictureUpload">
              <form-file
                v-model="question.attachments[1].attachment"
                :label="Text('ml_114653', 'Attachment')"
                :initalImgBase64="
                  AddBase64Head(question.attachments[1].attachmentBase64)
                "
                @userChange="
                  (file, picBase64) => uploadAttachment(file, picBase64, 1)
                "
              >
              </form-file>
            </v-card-text>
            <v-card-text v-if="!whetherHidePictureUpload">
              <form-file
                v-model="question.attachments[2].attachment"
                :label="Text('ml_114653', 'Attachment')"
                :initalImgBase64="
                  AddBase64Head(question.attachments[2].attachmentBase64)
                "
                @userChange="
                  (file, picBase64) => uploadAttachment(file, picBase64, 2)
                "
              >
              </form-file>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="onCancel" v-t="'ml_114660'">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="onOkay" v-t="'ml_114654'">
                OK
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {
  IQuestion,
  Question,
  File,
  CreateEmptyFile,
} from "@/models/question-section/question";
import Vue from "vue";
import {
  AddBase64Head,
  GoBack,
  RemoveBase64Head,
  GetTranslationRequired,
} from "@/utility/ui-utility";
import * as consts from "@/models/consts";
import {
  QuestionSectionType,
  QuestionType,
  RegularAnswer,
} from "@/models/enums";
import FormFile from "@/components/FormFile.vue";
import {
  CloneObj,
  ConsoleLog,
  DeepClone,
  IsNullOrEmpty,
  Text,
} from "@/utility";
import { StoreMutation } from "@/store/store-mapping";
import FormTextarea from "@/components/FormTextarea.vue";
import { mapGetters } from "vuex";
import { StoreAction, StoreGetter } from "@/store/store-mapping";
import { TractorThirdPartyCategory, ChassisThirdPartyCategory, VesselThirdPartyCategory } from "@/models/enums";
import { globals } from "@/main";
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    questionData: Object,
    lastValue: { type: String, default: "" },
  },
  components: {
    FormFile,
    FormTextarea,
    // RecordQuestionHead,
    // RecordQuestionRegular,
    // RecordQuestionAdditional,
    // HomePage,
  },
  data() {
    return {
      // question: { type: Question, default: null },
      // tmpPicString: "",
      valid: false,
      rules: {
        attachment: [
          (v: any) => !!v || GetTranslationRequired(),
          (v: any) =>
            (v && v.name && v.name.length > 0) || GetTranslationRequired(),
        ],
        issueDesc: [(v: any) => !!v || GetTranslationRequired()],
      },
      question: DeepClone(globals.$params.questionData) as IQuestion,
      originalQue: globals.$params.questionData as IQuestion,
      lastValuePassIn: globals.$params.lastValue,
    };
  },
  computed: {
    ...mapGetters({
      tripInfo: StoreGetter.emTripInfo,
    }),
    whetherHidePictureUpload(): boolean {
      ConsoleLog({ "whetherHidePictureUpload-trip": CloneObj(this.tripInfo) });
      ConsoleLog({ "whetherHidePictureUpload-question": CloneObj(this.originalQue) });      
      ConsoleLog({ "whetherHidePictureUpload-question-history": CloneObj(history.state) });
      let hidePictureUpload = false;
      if(this.originalQue.vehicleType == TractorThirdPartyCategory.TRACTOR 
          || this.originalQue.vehicleType == TractorThirdPartyCategory.RIGID_PGG_KNUCKLE_CRANE_TRUCK
          || this.originalQue.vehicleType == TractorThirdPartyCategory.RIGID_PGG_TRUCK
          || this.originalQue.vehicleType == TractorThirdPartyCategory.RIGID_TANKER){            
        if(this.tripInfo.isTractorThirdParty){
          hidePictureUpload = true;
        }
      }
      if(this.originalQue.vehicleType == ChassisThirdPartyCategory.CHASSIS_TRAILER
          || this.originalQue.vehicleType == ChassisThirdPartyCategory.PGG_CHASSIS){
        if(this.tripInfo.isChassisThirdParty){
          hidePictureUpload = true;
        }  
      }
      if(this.originalQue.vehicleType == VesselThirdPartyCategory.LB_MB_ISO_TANKER
          || this.originalQue.vehicleType == VesselThirdPartyCategory.TUBE_SKID){
        if(this.tripInfo.isVesselThirdParty){
          hidePictureUpload = true;
        }
      }      
      return hidePictureUpload;
    },
  },
  
  created(): void {
    // this.makePicture;
  },
  mounted(): void {
    // this.tmpPicString = this.question.attachmentBase64;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // ConsoleLog({ "mounted.tmpPicString": this.question.attachmentBase64 });
  },
  methods: {
    GoBack,
    Text,
    AddBase64Head,
    IsNullOrEmpty,
    uploadAttachment(file: any, picBase64: any, index: number) {
      // ConsoleLog(["uploadAttachment", file, picBase64, RemoveBase64Head(picBase64)]);
      ConsoleLog(["uploadAttachment", file]);
      if (file === null) {
        this.question.attachments[index].fileName = "";
        this.question.attachments[index].attachment = CreateEmptyFile();
        // this.tmpPicString = RemoveBase64Head(picBase64);
        this.question.attachments[index].attachmentBase64 = " ";
      } else {
        this.question.attachments[index].fileName = file.name;
        this.question.attachments[index].attachment = new File(
          file.name,
          file.size,
          file.type,
          file.lastModified
        );
        // this.tmpPicString = RemoveBase64Head(picBase64);
        this.question.attachments[index].attachmentBase64 =
          RemoveBase64Head(picBase64);
      }
    },
    onCancel() {
      console.log(`defect page, click cancel`);
      console.log(`defect page, click cancel, lastValue = ${this.lastValuePassIn}`);
      ConsoleLog({
        "originalQue": CloneObj(this.originalQue),
        question: CloneObj(this.question),
      });
      this.originalQue.answer = IsNullOrEmpty(this.lastValuePassIn)
        ? ""
        : this.lastValuePassIn;
      this.$store.commit(StoreMutation.emUpdateQuestion, {
        question: this.originalQue,
      });
      GoBack(this.$router);
    },

    onOkay() {
      // validation the attachment
      const self = this as any;
      if (!self.$refs.form.validate()) {
        return;
      }
      ConsoleLog({
        "originalQue": CloneObj(self.originalQue),
        question: CloneObj(self.question),
      });
      //set the pic string
      for (let i = 0; i < 3; i++) {
        this.originalQue.attachments[i].fileName =
          this.question.attachments[i].fileName;
        this.originalQue.attachments[i].attachment =
          this.question.attachments[i].attachment;
        self.originalQue.attachments[i].attachmentBase64 =
          this.question.attachments[i].attachmentBase64;
      }
      // self.originalQue.attachmentBase64 = self.tmpPicString;
      this.originalQue.issueDesc = this.question.issueDesc;
      ConsoleLog({ "beore emUpdateQuestion": CloneObj(self.originalQue) });
      self.$store.commit(StoreMutation.emUpdateQuestion, {
        question: self.originalQue,
      });
      ConsoleLog({ "pass validation at Defect page": "" });
      GoBack(this.$router);
    },
  },
});
</script>

<style></style>
