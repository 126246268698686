<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title v-t="'ml_115437'" class="color-red">Sign</v-card-title>
          <v-card-text>
            <div>
              <v-row>
              <VueSignaturePad width="320px" height="200px" 
              :options="{backgroundColor: 'rgb(70,240,240)'}" ref="signaturePad" />
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn v-t="'ml_115438'" color="primary" small @click="save">Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-t="'ml_115439'" color="primary" small @click="undo">Undo</v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="GoBack($router)" v-t="'ml_114660'">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" 
            :disabled="isNotSigned()"
            @click="onOkay" v-t="'ml_114654'"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { ImgeApiMode, PageName, SubmitStatus } from "@/models/enums";
import Vue from "vue";
import { GoBack, RemoveStorageEvcr } from "@/utility/ui-utility";

import { StoreAction, StoreGetter, StoreMutation } from "@/store/store-mapping";
import { mapGetters } from "vuex";
import {
  CloneObj,
  ConsoleLog,
  IsDebug,
  Timeout,
  validateOdometer,
  Text,
  GetDatePart,
  GetTimePart,
  IsNullOrEmpty,
  CountArrayDuplicate,
} from "@/utility";
import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    // RecordOverviewItem,
    // HomePage,
  },
  data() {
    return {
      errorMsg: "",
      signString:"",
    };
  },
  computed: {

    ...mapGetters({
      tripInfo: StoreGetter.emTripInfo,
      questions: StoreGetter.emQuestions,
    }),
  },
  methods: {
    Text,
    GoBack,
    async onOkay() {
      const self = this as any;
      self.errorMsg = "";
      const isGood = await this.submit();
      if (isGood) {
        this.$router.push({
          name: PageName.RecordQuestionSubmitResult,
          params: { action: SubmitStatus.Success },
        });
      }
    },
    async submit() {
      this.tripInfo.overviewImage=this.questions.imageName;
      this.updateTripInfo(false, 'overviewImage');
      this.tripInfo.signImage=this.signString;
      this.updateTripInfo(false, 'signImage');
      //submit request
      const self = this as any;
      let isOk = false;
      ConsoleLog("eVCR sumbit at question page");
      isOk = await self.$store.dispatch(StoreAction.emPostNewEvcrSign, {
        imageApiMode: ImgeApiMode.One,
        longitude: "2",
        //signString:this.signString,
      });
      if (this.$store.state.evcrModule.errorMsg.length > 0) {
        this.errorMsg = this.$store.state.evcrModule.errorMsg;
      }
      if (isOk) {
        this.$store.commit(StoreMutation.emResetEvcr);
        RemoveStorageEvcr();
      }
      return isOk;
    },

    undo() {
      //this.$refs.signaturePad.undoSignature();
      (this.$refs.signaturePad as any).undoSignature();
      this.signString="";
    },

    save() {
      //const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      const { isEmpty, data } = (this.$refs.signaturePad as any).saveSignature('image/jpeg');
      console.log(isEmpty);
      console.log(data);
      if(data){
      this.signString=data;}
      console.log(this.signString);
      
    },

    updateTripInfo(updatedDriver: boolean = false, type: string) {
      ConsoleLog({ updateTripInfo: CloneObj(this.tripInfo), type: type });
      const self = this as any;
      self.$store.commit(StoreMutation.emUpdateTripInfo, {
        tipInfo: this.tripInfo,
      });
      if (updatedDriver) {
        self.$store.commit(StoreMutation.emUpdateIsEdit, { isEdit: true });
      }
    },

    isNotSigned() {
      return this.signString === ""?true:false;
    },

  },
});
</script>

<style></style>
