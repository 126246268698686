<template>
  <home-page />
</template>

<script lang="ts">
import { ConsoleLog } from "@/utility";
import Vue from "vue";
import HomePage from "../components/HomePage.vue";

import { defineComponent } from 'vue';
export default defineComponent({
  name: "HomeEntry",

  components: {
    HomePage,
  },
  mounted() {
    ConsoleLog({
      "Home.mounted": document.body.scrollTop,
      "document.documentElement.scrollTop": document.documentElement.scrollTop,
    });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
});
</script>
