import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "red-error"
}
const _hoisted_2 = {
  key: 1,
  class: "red-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_section_list = _resolveComponent("section-list")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("eVCR Overview")
                    ])),
                    _: 1
                  })), [
                    [_directive_t, 'ml_114646']
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, {
        class: "mb-6",
        "no-gutters": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "6",
            class: "padding-top-3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                src: _ctx.VehiclePath(_ctx.questions.imageName)
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_section_list, { questions: _ctx.questions }, null, 8, ["questions"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  (_ctx.errorMsg.length > 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.errorMsg), 1))
                    : _createCommentVNode("", true),
                  (_ctx.submitMsg.length > 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.submitMsg), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      (_ctx.errorMsg.length > 0 && 1 > 2)
                        ? _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            color: "primary",
                            onClick: _ctx.loadQuestions
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("Reload")
                            ])),
                            _: 1
                          }, 8, ["onClick"])), [
                            [_directive_t, 'ml_114648']
                          ])
                        : _createCommentVNode("", true),
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        color: "primary",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.GoTripInfo(_ctx.$router))),
                        class: _normalizeClass(_ctx.globals.$lanCode === _ctx.LangCode.ms_MY ? 'btn-new-line' : '')
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" Trip Info")
                        ])),
                        _: 1
                      }, 8, ["class"])), [
                        [_directive_t, 'ml_114601']
                      ]),
                      _createVNode(_component_v_spacer),
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        color: "primary",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.GoToSummary())),
                        class: _normalizeClass(_ctx.globals.$lanCode === _ctx.LangCode.ms_MY ? 'btn-new-line' : '')
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Summary")
                        ])),
                        _: 1
                      }, 8, ["class"])), [
                        [_directive_t, 'ml_114650']
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}