import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "red-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_RecordQuestionSummaryItem = _resolveComponent("RecordQuestionSummaryItem")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Defect Summary")
                    ])),
                    _: 1
                  })), [
                    [_directive_t, 'ml_114655']
                  ]),
                  (_ctx.questions.containDefect || _ctx.questions.additionalQuestionContainDefect)
                    ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_RecordQuestionSummaryItem, { "record-question-summary-data": _ctx.questions }, null, 8, ["record-question-summary-data"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!(_ctx.questions.containDefect || _ctx.questions.additionalQuestionContainDefect))
                    ? _withDirectives((_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode(" No Defect ")
                        ])),
                        _: 1
                      })), [
                        [_directive_t, 'ml_114927']
                      ])
                    : _createCommentVNode("", true),
                  (_ctx.errorMsg.length > 0)
                    ? (_openBlock(), _createBlock(_component_v_card_text, { key: 2 }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.errorMsg), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        color: "primary",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.GoOverview(_ctx.$router)))
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode(" eVCR Overview ")
                        ])),
                        _: 1
                      })), [
                        [_directive_t, 'ml_114646']
                      ]),
                      _createVNode(_component_v_spacer),
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        color: "primary",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goNext()))
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" Submit ")
                        ])),
                        _: 1
                      })), [
                        [_directive_t, 'ml_114656']
                      ])
                    ]),
                    _: 1
                  }),
                  (_ctx.TestMode())
                    ? (_openBlock(), _createBlock(_component_v_card_actions, { key: 3 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitAll()))
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode(" AllNoWait ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitAllWait()))
                          }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode(" All Wait ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.submitOne()))
                          }, {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createTextVNode(" 1by1 ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.submitParallel()))
                          }, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode(" Parallel ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}