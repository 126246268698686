import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "align-center font-bolder" }
const _hoisted_2 = {
  key: 1,
  class: "red-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_textarea = _resolveComponent("form-textarea")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Recent Vehicle Condition")
                    ])),
                    _: 1
                  })), [
                    [_directive_t, 'ml_114640']
                  ]),
                  (_ctx.IsLocalMode())
                    ? (_openBlock(), _createBlock(_component_v_card_actions, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
                            color: "primary"
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode(" Back ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_spacer),
                          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            color: "primary",
                            onClick: _ctx.onNext
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode(" Next ")
                            ])),
                            _: 1
                          }, 8, ["onClick"])), [
                            [_directive_t, 'ml_114639']
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
                    return (_openBlock(), _createBlock(_component_v_card_text, { key: index }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.Text("ml_114722", "Item") + (index + 1)), 1),
                        _createVNode(_component_form_input, {
                          modelValue: notification.vehicleEquipment,
                          "onUpdate:modelValue": ($event: any) => ((notification.vehicleEquipment) = $event),
                          readonly: "",
                          label: _ctx.Text('ml_114641', 'Vehicle/Equipment')
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                        _createVNode(_component_form_input, {
                          modelValue: notification.notificationId,
                          "onUpdate:modelValue": ($event: any) => ((notification.notificationId) = $event),
                          label: _ctx.Text('ml_114903', 'Notification ID'),
                          readonly: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                        _createVNode(_component_form_input, {
                          modelValue: notification.desc,
                          "onUpdate:modelValue": ($event: any) => ((notification.desc) = $event),
                          label: _ctx.Text('ml_114642', 'Noti. Description'),
                          readonly: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                        _createVNode(_component_form_textarea, {
                          modelValue: notification.defectDesc,
                          "onUpdate:modelValue": ($event: any) => ((notification.defectDesc) = $event),
                          label: _ctx.Text('ml_114904', 'Defect Description'),
                          readonly: _ctx.disable,
                          rows: "2"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "readonly"]),
                        _createVNode(_component_form_input, {
                          modelValue: notification.date,
                          "onUpdate:modelValue": ($event: any) => ((notification.date) = $event),
                          label: _ctx.Text('ml_114643', 'Notification Date'),
                          readonly: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                        _createVNode(_component_form_input, {
                          modelValue: notification.time,
                          "onUpdate:modelValue": ($event: any) => ((notification.time) = $event),
                          label: _ctx.Text('ml_114644', 'Notification Time'),
                          readonly: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                        _createVNode(_component_form_input, {
                          modelValue: notification.status,
                          "onUpdate:modelValue": ($event: any) => ((notification.status) = $event),
                          label: _ctx.Text('ml_114645', 'Status'),
                          readonly: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  (_ctx.errorMsg.length > 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.errorMsg), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.go(-1))),
                        color: "primary"
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" Back ")
                        ])),
                        _: 1
                      })), [
                        [_directive_t, 'ml_114638']
                      ]),
                      _createVNode(_component_v_spacer),
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        color: "primary",
                        onClick: _ctx.onNext
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" Next ")
                        ])),
                        _: 1
                      }, 8, ["onClick"])), [
                        [_directive_t, 'ml_114639']
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}