import axios, { AxiosStatic } from 'axios';
import AuthService, { authService } from '@/services/AuthorService';
import { Component, Vue, toNative } from 'vue-facing-decorator';
import config from '@/config/config.json';
import { CloneObj, ConsoleLog, DeepClone, RequestConfirmWithEnv, Text } from '@/utility';
import { ServiceCallResponse } from '@/models/service';

export default class APIService {
    constructor() {

    }

    public async GetRequest(url: string, token: string) {
        if (!config.app.useLamda) {
            return await this.GetRequestLocal(url, token);
        } else {
            return await this.GetRequestLamda(url, token);
        }
    }

    public async GetRequestLocal(url: string, token: string) {
        try {
            // const token = await this._authService.getAccessToken();
            // const accessToken = "YW1lcmljYVx4bXByb3N2YzpTdG16MTIzIw==";
            const accessToken = "QVNJQVBBQ1xBU0ZNU1ZDRDpBcDEyMzQ1Ng==";
            // const url = `${config.app.api_domain}${urlPath}`;
            let headers = { headers: { 'Content-Type': 'application/json;charset=utf-8', 'Authorization': 'Basic ' + accessToken, "X-APIKEY": `${config.app.api_key}` } };
            // let headers = { headers: { 'Content-Type': 'application/json;charset=utf-8', "X-APIKEY": `${config.app.api_key}` } };    
            ConsoleLog({ 'GetRequest.url': url });
            ConsoleLog({ 'GetRequest.headers': headers });
            const response = await axios.get(url, headers) as Response;
            ConsoleLog({ 'GetRequest.response': response });
            // if (!response) {
            //     return new ServiceCallResponse(false, 'no response info', '', []);
            // }
            return await this.ResponseProcessor(response)
        } catch (error: any) {
            ConsoleLog({ 'GetRequest.catch.error': error });
            if (error?.response?.status === 400) {
                return new ServiceCallResponse(false, error.response.data, '', [], error.response.status);
            }
            return new ServiceCallResponse(false, error, '', [], error.response.status);
        }
    }

    public async GetRequestLamda(url: string, token: string) {
        try {
            // const token = await this._authService.getAccessToken();
            const accessToken = "YW1lcmljYVx4bXByb3N2YzpTdG16MTIzIw==";
            // const url = `${config.app.api_domain}${urlPath}`;
            // let headers = { headers: { 'Content-Type': 'application/json;charset=utf-8', 'mode': 'no-cors', 'Access-Control-Allow-Origin': '*', "Access-Control-Allow-Credentials": "true", "Access-Control-Max-Age": "1800", "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization", "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTIONS" } };
            // let headers = { headers: { 'Content-Type': 'application/json;charset=utf-8', "X-APIKEY": `${config.app.api_key}` } };    /, 'Access-Control-Allow-Origin': '*' 
            //{ crossdomain: true }
            let headers = { headers: { 'Content-Type': 'application/json;charset=utf-8' } };
            ConsoleLog({ 'GetRequest.url': url });
            ConsoleLog({ 'GetRequest.headers': headers });
            const response = await axios.get(url, headers) as Response;
            ConsoleLog({ 'GetRequest.response': response });
            // if (!response) {
            //     return new ServiceCallResponse(false, 'no response info', '', []);
            // }
            return await this.ResponseProcessor(response)
        } catch (error: any) {
            ConsoleLog({ 'GetRequest.catch.error': error });
            if (error?.response?.status === 400) {
                return new ServiceCallResponse(false, error.response.data, '', [], error.response.status);
            }
            return new ServiceCallResponse(false, error, '', [], error.response?.status);
        }
    }

    public async GetRequestToken(url: string, token: string) {
        try {
            let headers = { headers: { 'Content-Type': 'application/json;charset=utf-8', 'Authorization': "Bearer " + token } };
            ConsoleLog({ 'GetRequestToken.url': url });
            ConsoleLog({ 'GetRequestToken.headers': headers });
            const response = await axios.get(url, headers) as Response;
            ConsoleLog({ 'GetRequestToken.response': response });
            return new ServiceCallResponse(true, '', '', (response as any).data, response.status);
            // return await this.ResponseProcessor(response)
        } catch (error: any) {
            ConsoleLog({ 'GetRequestToken.catch.error': error });
            if (error?.response?.status === 400) {
                return new ServiceCallResponse(false, error.response.data, '', [], error.response.status);
            }
            return new ServiceCallResponse(false, error, '', [], error.response?.status);
        }
    }

    public async PostRequest(url: string, post: string, token: string) {
        if (!config.app.useLamda) {
            return await this.PostRequestLocal(url, post, token);
        } else {
            return await this.PostRequestLamda(url, post, token);
        }
    }

    public async PostRequestLocal(url: string, postData: any, token: string) {
        try {
            ConsoleLog({ 'PostRequest.postDataLocal': postData });
            // const token = await this._authService.getAccessToken();
            // if (token == null) return '认证失败，系统自动重新登录';
            //set the access_token for calling the api
            const accessToken = "YW1lcmljYVx4bXByb3N2YzpTdG16MTIzIw==";
            // let headers = { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer ' + token.accessToken } };
            let headers = { headers: { 'Content-Type': 'application/json;charset=utf-8', 'Authorization': 'Basic ' + accessToken, "X-APIKEY": `${config.app.api_key}` } };
            //const response = await axios.post('https://m4phn0i3d3.execute-api.us-east-1.amazonaws.com/Prod/api/values', '=' + postData, headers);
            const response = await axios.post(url, JSON.stringify(postData), headers) as Response;
            ConsoleLog({ 'PostRequest.response': response });
            return await this.ResponseProcessor(response)
        }
        catch (error: any) {
            ConsoleLog({ 'PostRequest.catch.error': error });
            if (error?.response?.status === 400) {
                return new ServiceCallResponse(false, error.response.data, '', [], error.response.status);
            }
            return new ServiceCallResponse(false, error, '', [], error.response.status);
        }
    }
    public async PostRequestLamda(url: string, postData: any, token: string) {
        try {
            ConsoleLog({ 'PostRequest.postData': postData });
            // let headers = { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer ' + token.accessToken } };
            let headers = { headers: { 'Content-Type': 'application/json;charset=utf-8', 'Authorization': 'Bearer ' + token } }
            const response = await axios.post(url, JSON.stringify(postData), headers) as Response;
            ConsoleLog({ 'PostRequest.response': response });
            return await this.ResponseProcessor(response)
        }
        catch (error: any) {
            ConsoleLog({ 'PostRequest.catch.error': error });
            if (error?.response?.status === 400) {
                return new ServiceCallResponse(false, error.response.data, '', [], error.response.status);
            }
            if (error?.response?.status === 504) {
                return new ServiceCallResponse(false, error.response.data, '', [], error.response.status);
            }
            if (error?.response?.status === 403) {
                return new ServiceCallResponse(false, error.response.data, '', [], error.response.status);
            }
            return new ServiceCallResponse(false, error, '', [], error.response?.status ?? 0);
        }
    }
    async ResponseProcessor(response: Response) {

        if (response.status === 200) {
            return new ServiceCallResponse(true, '', '', (response as any).data?.d?.results, response.status);
        } else if (response.status === 201) {
            return new ServiceCallResponse(true, '', '', (response as any).data?.d, response.status);
        } else if (response.status === 401) {
            return new ServiceCallResponse(false, Text('ml_114693', 'Authentication timeout. Please refresh to page to re-login.'), '', [], response.status);
        } else if (response.status === 504) {
            return new ServiceCallResponse(false, Text('ml_114694', 'Gateway timeout, please try again.'), '', [], response.status);
        }
        // else if (response.status === 400) {
        //     return new ServiceCallResponse(false, 'Gateway timeout, please try again.', '', [], response.status);
        // }

        const errorText = await response.text();
        ConsoleLog({ 'ResponseProcessor.error': errorText });
        return new ServiceCallResponse(false, errorText, '', [], response.status);
    }

}
export const apiService = new APIService();
