import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "color-red" }
const _hoisted_2 = { class: "red-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_v_card_title, { class: "color-red" }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Caution!")
                    ])),
                    _: 1
                  })), [
                    [_directive_t, 'ml_114658']
                  ]),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_1, _cache[2] || (_cache[2] = [
                        _createTextVNode(" You have critical defects on the eVCR form, please don’t start the trip (if you are on pre-trip) and contact your supervisor for the guidance of next step. ")
                      ]))), [
                        [_directive_t, 'ml_114659']
                      ])
                    ]),
                    _: 1
                  }),
                  (_ctx.errorMsg.length > 0)
                    ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.errorMsg), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        color: "primary",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.GoBack(_ctx.$router)))
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" Cancel ")
                        ])),
                        _: 1
                      })), [
                        [_directive_t, 'ml_114660']
                      ]),
                      _createVNode(_component_v_spacer),
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        color: "primary",
                        onClick: _ctx.onOkay
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" OK ")
                        ])),
                        _: 1
                      }, 8, ["onClick"])), [
                        [_directive_t, 'ml_114654']
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}