<template>
  <div id="ap-loading-overlay">
    <div id="ap-loading-image"></div>
  </div>
</template>
<script>
import Vue from "vue";

export default{
  name: "LoadingOverlay",
}
</script>
