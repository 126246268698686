import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "red-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  (_ctx.action === _ctx.SubmitStatus.Success)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _withDirectives((_openBlock(), _createBlock(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[0] || (_cache[0] = [
                            _createTextVNode("Notice")
                          ])),
                          _: 1
                        })), [
                          [_directive_t, 'ml_114661']
                        ]),
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            _withDirectives((_openBlock(), _createElementBlock("p", null, _cache[1] || (_cache[1] = [
                              _createTextVNode(" eVCR has been submitted successfully. System will go to home page in 3 seconds. ")
                            ]))), [
                              [_directive_t, 'ml_114662']
                            ])
                          ]),
                          _: 1
                        })
                      ], 64))
                    : (_ctx.action === _ctx.SubmitStatus.Failure)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_v_card_title, null, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createTextVNode("Warning")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _cache[3] || (_cache[3] = _createElementVNode("p", null, "eVCR has not been submitted successfully.", -1)),
                              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.errorMsg), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                                color: "primary",
                                onClick: _ctx.goPre
                              }, {
                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                  _createTextVNode(" Back ")
                                ])),
                                _: 1
                              }, 8, ["onClick"])), [
                                [_directive_t, 'ml_114638']
                              ])
                            ]),
                            _: 1
                          })
                        ], 64))
                      : (_ctx.action === _ctx.SubmitStatus.Processing)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode("Information")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createElementVNode("p", null, "submitting is in process.", -1)
                              ])),
                              _: 1
                            })
                          ], 64))
                        : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}