import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecordQuestionSummaryRegularItem = _resolveComponent("RecordQuestionSummaryRegularItem")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return _withDirectives((_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { cols: "12" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recordQuestionSummaryRegularData.questions, (question, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (question.notificationIsNeeded)
                ? (_openBlock(), _createBlock(_component_RecordQuestionSummaryRegularItem, {
                    key: index,
                    "record-question-summary-regular-item-data": question
                  }, null, 8, ["record-question-summary-regular-item-data"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512)), [
    [_vShow, _ctx.recordQuestionSummaryRegularData.containDefect]
  ])
}