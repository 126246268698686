import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecordQuestionSummaryRegularItem = _resolveComponent("RecordQuestionSummaryRegularItem")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recordQuestionSummaryAdditionalData.questions, (question, questionIndex) => {
            return (_openBlock(), _createBlock(_component_RecordQuestionSummaryRegularItem, {
              key: questionIndex,
              "record-question-summary-regular-item-data": question
            }, null, 8, ["record-question-summary-regular-item-data"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}