import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecordQuestionHead = _resolveComponent("RecordQuestionHead")!
  const _component_RecordQuestionRegular = _resolveComponent("RecordQuestionRegular")!
  const _component_RecordQuestionAdditional = _resolveComponent("RecordQuestionAdditional")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_RecordQuestionHead, {
                        "record-question-head-data": 
                _ctx.newRecordData.questionAnwerSectionTractor
              
                      }, null, 8, ["record-question-head-data"]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newRecordData
                .questionAnwerSectionTractor.regularQuestionCollection, (sectionData, sectionDataIndex) => {
                        return (_openBlock(), _createBlock(_component_RecordQuestionRegular, {
                          key: sectionDataIndex,
                          "record-question-regular-data": sectionData
                        }, null, 8, ["record-question-regular-data"]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newRecordData
                .questionAnwerSectionTractor.additionalQuestionCollection, (sectionData, sectionDataIndex) => {
                        return (_openBlock(), _createBlock(_component_RecordQuestionAdditional, {
                          key: sectionDataIndex,
                          "record-question-additional-data": sectionData
                        }, null, 8, ["record-question-additional-data"]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: _ctx.next
                      }, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode(" Home ")
                        ])),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: _ctx.next
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode(" Trip Info ")
                        ])),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: _ctx.next
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" Recent Vehicle Condition ")
                        ])),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: _ctx.goToRecordQuestionDefectSummary
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" Next ")
                        ])),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}