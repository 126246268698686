<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card>
          <v-card-title v-t="'ml_114663'"
            >Search for the last eVCR</v-card-title
          >

          <v-card-text>
            <v-card-actions>
              <form-autocomplete
                :items="vehicles(SapVehcileCategory.Tractor)"
                v-model="$store.state.lastEvcrModule.tractor"
                :label="Text('ml_114631', 'Tractor/Rigid/Truck')"
                :rules="rules.tractor"
                itemText="vehicleId"
                itemValue="vehicleId"
                :userFilter="
                  (item, queryText, itemText) =>
                    onUserVehicleFilter(
                      item,
                      queryText,
                      itemText,
                      SapVehcileCategory.Tractor
                    )
                "
                @userChange="
                  (newValue) =>
                    onVehicleChange(newValue, '', SapVehcileCategory.Tractor)
                "
              ></form-autocomplete>
              <v-spacer></v-spacer>
              <qr-scaner
                formId="formIdTractor"
                :initialValue="$store.state.lastEvcrModule.tractor"
                :labelText="Text('ml_114631', 'Tractor/Rigid/Truck')"
                @handleChange="handleTractorChange"
              />
            </v-card-actions>
            <div class="red-error">{{ validationMessage }}</div>
            <!-- <div v-if="decodedContent.length > 0">
              <span> Tractor/Rigid/Truck is</span>
              <span>
                <b>&nbsp;{{ decodedContent }}</b
                >.
              </span>
            </div> -->

            <v-card-actions>
              <v-btn color="primary" @click="GoBack($router)" v-t="'ml_114649'"
                >Home</v-btn
              >
              <!-- <v-btn
                color="primary"
                @click="retry"
                v-if="this.$store.state.lastEvcrModule.tractor.length > 0"
                v-t="'ml_114666'"
                >Retry</v-btn
              > -->
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                v-show="
                  !IsNullOrEmpty(this.$store.state.lastEvcrModule.tractor)
                "
                @click="selectId"
                v-t="'ml_114639'"
                >Next</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
// import HomePage from "../components/HomePage.vue";
import Vue from "vue";
import FormAutocomplete from "@/components/FormAutocomplete.vue";
import { Timeout, Text, ConsoleLog, DeepClone, IsNullOrEmpty } from "@/utility";
import {
  GoHome,
  GoQuestionTractor,
  GoTripInfo,
  GoCondition,
  GoOverview,
  GoBack,
  GetTranslationRequired,
} from "@/utility/ui-utility";
import { PageName, SapVehcileCategory } from "@/models/enums";
import { CreateTripInfo, TripInfo } from "@/models/trip-info";
import { StoreAction, StoreGetter, StoreMutation } from "@/store/store-mapping";
import * as consts from "@/models/consts";
import { mapGetters } from "vuex";
import QrScaner from "@/components/QrScaner.vue";
import { Vehicle } from "@/models/vehicle";
import { defineComponent } from 'vue';
export default defineComponent({
  components: { QrScaner, FormAutocomplete },
  data() {
    return {
      decodedContent: "",
      isValid: { type: Boolean, default: undefined },
      validationMessage: "",
      rules: {
        tractor: [(v: string) => !!v || GetTranslationRequired()],
      },
      SapVehcileCategory,
    };
  },
  computed: {
    ...mapGetters({
      vehicles: StoreGetter.emVehicles,
      userCountryCode: StoreGetter.umUserCountryCode,
    }),
  },
  async mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  beforeMount() {},

  destroyed() {},
  async created() {
    this.validationMessage = "";
    this.$store.state.lastEvcrModule.errorMsg = "";
    await this.initialize();
  },

  methods: {
    GoBack,
    Text,
    IsNullOrEmpty,
    handleTractorChange(e: string) {
      ConsoleLog({ handleTractorChange: e });
      const scannedData = e.trim();
      this.$store.commit(StoreMutation.lemUpdateTractor, {
        tractorData: scannedData,
      });
    },
    async selectId() {
      const self = this as any;
      this.validationMessage = "";
      const tractValue = this.$store.state.lastEvcrModule.tractor.trim();
      if (tractValue !== this.$store.state.lastEvcrModule.tractor) {
        this.$store.commit(StoreMutation.lemUpdateTractor, {
          tractorData: tractValue,
        });
      }
      if (tractValue.length === 0) {
        this.validationMessage = Text(
          "ml_114679",
          "Please type in or scan the Tractor / Rigid / Truck."
        );
        return;
      }
      const trip = CreateTripInfo();
      trip.tractor = tractValue;
      await self.$store.dispatch(StoreAction.lvmGetLastEvcr, {
        trip: trip,
      });
      if (this.showStateMsg("SearchPage.initialize.emGetVehicle")) {
        return;
      }
      self.$router.push({
        name: PageName.TripInformationView,
        params: { trip: trip },
      });
    },
    async initialize() {
      const self = this as any;
      self.$store.commit(StoreMutation.StartLoading, null, { root: true });
      try {
        await self.$store.dispatch(StoreAction.emGetVehicle, {
          countryCode: this.userCountryCode,
        });
        if (this.showStateMsg("SearchPage.initialize.emGetVehicle")) {
          this.hidenLoading();
          return;
        }
      } catch (error) {
        ConsoleLog({ "SearchPage.initialize": error });
      }
      this.hidenLoading();
    },
    hidenLoading() {
      this.$store.commit(StoreMutation.EndLoading, null, { root: true });
    },
    showStateMsg(logName: string = "") {
      let hasError = false;
      if (this.$store.state.lastEvcrModule.errorMsg.length > 0) {
        this.validationMessage = this.$store.state.lastEvcrModule.errorMsg;
        if (!IsNullOrEmpty(logName)) {
          ConsoleLog({ logName: this.validationMessage });
        }
        hasError = true;
      }
      return hasError;
    },
    onUserVehicleFilter(
      item: Vehicle,
      queryText: string,
      itemText: string,
      category: string
    ): boolean {
      const searchText = queryText.toLowerCase();
      let found = false;
      found =
        item.category.toLowerCase() === category.toLowerCase() &&
        item.vehicleId.toLowerCase().indexOf(searchText) > -1;
      // console.log("onUserFilter", item, queryText, itemText, found);
      return found;
    },
    onVehicleChange(newValue: string, tripInfo: any, category: string): void {
      this.$store.state.lastEvcrModule.tractor = newValue;
    },
  },
});
</script>

<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
</style>
