<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <!-- <RecordQuestionHead v-for="itemData in recordQuestionHeadData.dataCollection" :key="itemData.title" :record-question-head-data="itemData" /> -->
            <RecordQuestionHead
              :record-question-head-data="
                newRecordData.questionAnwerSectionTractor
              "
            />
            <RecordQuestionRegular
              v-for="(sectionData, sectionDataIndex) in newRecordData
                .questionAnwerSectionTractor.regularQuestionCollection"
              :key="sectionDataIndex"
              :record-question-regular-data="sectionData"
            />
            <RecordQuestionAdditional
              v-for="(sectionData, sectionDataIndex) in newRecordData
                .questionAnwerSectionTractor.additionalQuestionCollection"
              :key="sectionDataIndex"
              :record-question-additional-data="sectionData"
            />
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" @click="next"> Home </v-btn>
            <v-btn color="primary" @click="next"> Trip Info </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn color="primary" @click="next">
              Recent Vehicle Condition
            </v-btn>
            <v-btn color="primary" @click="goToRecordQuestionDefectSummary">
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script lang="ts">
import Vue from "vue";
import RecordQuestionHead from '../components/RecordQuestionHead.vue'
import RecordQuestionRegular from '../components/RecordQuestionRegular.vue'
import RecordQuestionAdditional from '../components/RecordQuestionAdditional.vue'
// import HomePage from "../components/HomePage.vue";

import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    RecordQuestionHead,
    RecordQuestionRegular,
    RecordQuestionAdditional,
    // HomePage,
  },
  data() {
    return {
    }
  },
  computed: {

  },
  methods: {
    // next() {
    //   this.step += 1
    // },
    // previous() {
    //   this.step -= 1
    // },
    goToRecordQuestionDefectSummary() {
      this.$router.push({
        name: 'RecordQuestionDefectSummary',
        // params: { recordQuestionData: questionRegular },
      })
    },
    submitOrder() {
      this.$router.push({ name: 'thankyou' })
    },
  },
});
</script>

<style></style>
