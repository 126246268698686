import { PageName, PicType, QuestionSectionType, SapReturnCode, TripType } from "@/models/enums";
import { ConsoleLog, ConvertBrowserLanguage, IsNullOrEmpty, Text } from ".";
import * as consts from "@/models/consts"
import { SelectItem } from "@/models/select-item";
import Vue from "vue";
import {i18n} from "@/I18n";
import config from '@/config/config.json';
import { SubSection } from "@/models/question-section/sub-section";
import { globals } from "@/main";

//import { useCurrentApp } from '@/App.vue';

//const app = useCurrentApp()

export const ImagePath = (picType: PicType) => {
    if (picType === PicType.Tractor) {
        return require('@/assets/image/PictureTractor.jpg')
    } else if (picType === PicType.Chassis) {
        return require('@/assets/image/PictureChassis.jpg')
    } else if (picType === PicType.Vessel) {
        return require('@/assets/image/PictureVessel.jpg')
    }
}

export const VehiclePath = (fileName: string) => {
    // fileName = "Vehicle.png";
    const filePath = config.app.vehicle_pic_path;
    const fileFullPath = `${filePath}${fileName}`;
    // ConsoleLog({ "VehiclePath.fileName": fileFullPath });
    try {
        const img = require('@/assets/' + fileFullPath)
        // const img = require(`@/assets/${fileName}`) //doesn't support
        // ConsoleLog({ "VehiclePath": img });
        return img;
    } catch (error) {
        ConsoleLog({ "VehiclePath.error": error });
        return null;
    }
}
export const VehicleThumbnail = (fileName: string) => {
    // fileName = "Vehicle_thumb.png";
    const filePath = `${config.app.vehicle_pic_path}${fileName}`;
    // ConsoleLog({ "VehicleThumbnail.fileName": filePath });
    try {
        const img = require('@/assets/' + filePath)
        // ConsoleLog({ "VehicleThumbnail": img });
        return img;
    } catch (error) {
        ConsoleLog({ "VehicleThumbnail.error": error });
        return null;
    }
}
// export const HomeIcon = () => {
//     return require(`@/assets/image/home-40.png`);
// }
export const Confirm = (message: string) => {
    return window.confirm(message)
}
export const Alert = (message: string) => {
    window.alert(message)
}
export const GoHome = (router: any, needConfirm: boolean = true) => {
    // if (needConfirm) {
    //     if (!Confirm('The Unsaved information will be lost, are you sure to navigate to Home?')) {
    //         return;
    //     }
    // }
    router.push({ name: PageName.HomePage });
}
export const FuelHome = () => {
    return config.app.fuelHome
}
export const GoBack = (router: any) => {
    router.go(-1);
}
export const GoTripInfo = (router: any) => {
    router.push({ name: PageName.TripInformation });
}
export const GoCondition = (router: any) => {
    router.push({ name: PageName.VehicleCondition });
}
export const GoSearch = (router: any) => {
    router.push({ name: PageName.SearchPage });
}
export const GoQuestionsView = (router: any) => {
    router.push({ name: PageName.QuestionsView });
}

export const GoQuestionWithHash = (router: any, picType: PicType, subSectionId: string) => {
    let sectionType = QuestionSectionType.Tractor;
    switch (picType) {
        case PicType.Tractor:
            sectionType = QuestionSectionType.Tractor;
            break;
        case PicType.Vessel:
            sectionType = QuestionSectionType.Vessel;
            break;
        case PicType.Chassis:
            sectionType = QuestionSectionType.Chassis;
            break;
    }
    return `record-question/${sectionType.toString()}#${consts.STR_SUBSECTION_ID + subSectionId}`;
}

export const GetQueSectionUrl = (subSectionId: string) => {
    return `/record-question/${subSectionId}`;
    ///record-question/:sectionId
}

export const GoQuestion = (router: any, suSectionId: string) => {
    router.push({
        name: PageName.QueSection,
        params: {
            subSectionId: suSectionId,
        },
    });
}

export const GoQuestionTractor = (router: any) => {
    router.push({
        name: PageName.RecordQuestionTractor,
        params: {
            type: QuestionSectionType.Tractor,
        },
    });
}

export const GoQuestionChassis = (router: any) => {
    router.push({
        name: PageName.RecordQuestionChassis,
        params: {
            type: QuestionSectionType.Chassis,
        },
    });
}
export const GoQuestionVessel = (router: any) => {
    router.push({
        name: PageName.RecordQuestionVessel,
        params: {
            type: QuestionSectionType.Vessel,
        },
    });
}

export const GoSummary = (router: any) => {
    router.push({ name: PageName.RecordQuestionDefectSummary });
}

export const GoOverview = (router: any) => {
    router.push({ name: PageName.RecordOverview });
}

export const GoDefect = (router: any, parameters: any) => {
    globals.$params={ ...parameters}
    router.push({
        name: PageName.RecordQuestionDefect,
        params: { ...parameters},
        // query: { timestamp: Date.now() }
    });
}

export const GoGood = (router: any, parameters: any) => {
    globals.$params={ ...parameters}
    router.push({
        name: PageName.RecordQuestionGood,
        params: { ...parameters },
        // query: { timestamp: Date.now() }
    });
}

export const RemoveBase64Head = (base64Data) => {
    if (0 == base64Data.indexOf("data:image/")) {
        let c = base64Data.indexOf("base64,") + 7;
        base64Data = base64Data.substring(c);
        return base64Data;
    } else {
        return base64Data;
    }
};

export const AddBase64Head = (base64Data) => {
    if (IsNullOrEmpty(base64Data)) {
        return base64Data;
    }
    return `data:image/png;base64,${base64Data}`
};



export const HandleSapReturnCode = (error: any, defaultMsg: string = "") => {
    let errorMsg = defaultMsg.length == 0 ? "Encountered error that back-end doesn't return the specified error code." : defaultMsg;
    const errorCode = error?.error?.code as SapReturnCode;
    ConsoleLog(['HandleSapReturnCode', error, errorCode]);
    if (errorCode && error?.error?.message?.value) {
        errorMsg = error?.error?.message?.value;
    }
    return errorMsg;
}

export const IsEditEvcr = () => {
    let isEdit = false;
    const isEditStorage = localStorage.getItem(consts.EVCR_IS_EDIT_KEY);
    if (isEditStorage && isEditStorage === true.toString()) {
        isEdit = true;
    }
    return isEdit;
};

export const SetIsEditEvcr = (isEdit: boolean) => {
    localStorage.setItem(consts.EVCR_IS_EDIT_KEY, isEdit.toString());
};

export const RemoveStorageEvcr = () => {
    localStorage.removeItem(consts.EVCR_DATA_KEY)
    SetIsEditEvcr(false);
};

export const GetTranslationRequired = () => {
    return Text("ml_114626", consts.FIELD_REQUIRED);
}
export const GetTranslationAdditionalQues = () => {
    return Text('ml_114685', consts.STR_ADDTIONAL_QUE);
}

export const PrePostItems = () => {
    // await IntialLangData();
    let items: SelectItem[] = [];
    items.push(new SelectItem(Text("ml_114687", "Pre"), "PR"));
    items.push(new SelectItem(Text("ml_114688", "Post"), "PO"));
    // ConsoleLog({
    //     "PrePostItems": items,
    // });
    return items;
};

export const TripTypetems = () => {
    let items: SelectItem[] = [];
    items.push(new SelectItem(Text("ml_114898", "LB/MB"), TripType.LB));
    items.push(new SelectItem(Text("ml_114899", "PG"), TripType.PG));
    items.push(new SelectItem(Text("ml_115441", "Others"), TripType.Other));
    return items;
};

export const BoolItems = [
    new SelectItem("true", "true"),
    new SelectItem("false", "false"),
];

export const RegularItems = () => {
    let items: SelectItem[] = [];
    const regularItem = [new SelectItem(Text("ml_114689", "Good"), "Good"),
    new SelectItem(Text("ml_114690", "Defective"), "Defective"),
    new SelectItem(Text("ml_114691", "N/A"), "N/A")];
    items.push(...regularItem);
    return items;
};

export const IntialLangData = async () => {
    const language = ConvertBrowserLanguage(window.navigator.language);
    ConsoleLog({
        "window.navigator.language": window.navigator.language,
        language: language,
    });
    globals.$lanCode = language;
    await (i18n as any).loadLanguageAsync(language);
}

export const IsLocalMode = () => {
    return config.app.localMode ? true : false;
}

export const SubSectionStyle = (subSection: SubSection) => {
    let cssclass = "margin-5";
    if (subSection.validated) {
        cssclass += ' back-groud-green'; //color-red
    }
    return cssclass;
}

export const QuestionsPicName = (tmpName: string) => {
    return `${tmpName}.jpg`;
}

export const QuestionsThumbnailName = (tmpName: string) => {
    return `${tmpName}_THUMB.jpg`;
}

export const TestMode = () => {
    return config.app.test_mode ? true : false;
}

export const GetPicSize = (fileName: string) => {
    const picFile = VehiclePath(fileName);
    let picSize = { height: '100', width: '60' };
    try {
        // const img = require("@/" + picFile)
        if (picFile.toString().length > 0) {
            // ConsoleLog({ "picFile": picFile });
            let image = new Image();
            image.src = picFile;
            if (image.height === 0) {
                image.height = 120;
                image.width = 60;
            }
            // ConsoleLog({ "image": image });
            picSize.height = image.height.toString();
            picSize.width = image.width.toString();
        }
        ConsoleLog({ "GetPicSize": picSize, "fileName": fileName, "img": picFile });
    } catch (error) {
        ConsoleLog({ "GetPicSize": picSize, "fileName": fileName, "error": error });
    }
    return picSize;
}
