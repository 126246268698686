<template>
  <div>
  <v-row class="margin-0">
    <div v-if="required" :class="hardStop ? 'back-ground-light-grey color-white' : ''"><span class="red-error"><strong>* </strong></span>
      {{ label }}
    </div>
  </v-row>
  <v-row class="margin-0">
    

    <v-row class="margin-0">
      <v-col :cols="!showQuestionIcon && !showEditIcon ? 12 : ''" class="padding-0">
        <v-radio-group
          v-model="selectedValue"
          inline
          :rules="rules"
          v-on:update:modelValue="onChanging"
          :readonly="readonly"
          class="margin-0"
          
        >
          <template v-for="item in items" :key="item.itemValue">
            <v-radio 
              :label="item.itemText"
              :value="item.itemValue"
              :class="cssAnswer(item.itemValue, selectedValue)"
            >
              <template #label class="font-size-14">
                <span :class="cssLabel(item.itemValue, selectedValue)">{{
                  item.itemText
                }}</span>
              </template>
            </v-radio>
          </template>
        </v-radio-group>
      </v-col>
      <!-- <v-col :cols="2">
        <v-icon
          color="green-darken-2"
          icon="mdi-pencil"
          size="large"
        ></v-icon>
      </v-col> -->
      <v-col
        :cols="showEditIcon ? 1 : 2"
        class="padding-0 alignMiddle"
        v-show="showQuestionIcon"
      >
        <v-icon @click="questionCick" v-show="showQuestionIcon" color="darken-1" icon="mdi-map-marker-question"></v-icon>
      </v-col>
      <v-col
        :cols="showQuestionIcon ? 1 : 2"
        class="padding-0 alignMiddle"
        v-show="showEditIcon"
      >
        <v-icon @click="editCick" v-show="showEditIcon" icon="mdi-pencil" color="darken-1"></v-icon>
        <!-- left=true -->
      </v-col>
    </v-row>
  </v-row>
  </div>
</template>

<script lang="ts">
import { ISelectItem } from "@/models/select-item";
import { ConsoleLog, Timeout } from "@/utility";
import Vue from "vue";
import { mdiPencil, mdiMapMarkerQuestion } from "@mdi/js";
import { RegularAnswer } from "@/models/enums";

import { defineComponent } from 'vue';
export default defineComponent({
  emits: ['update:modelValue', 'userEditClick', 'userQuestionClick', 'userChange'],
  props: {
    modelValue: String || Number,
    label: String,
    items: Array<any>,
    rules: Array<any>,
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    showQuestionIcon: { type: Boolean, default: false },
    showEditIcon: { type: Boolean, default: false },
    hardStop: { type: Boolean, default: false },
  },
  data: () => ({
    selectedValue: null,
    icons: {
      mdiPencil,
      mdiMapMarkerQuestion,
    },
    lastValue: "",
  }),
  computed: {},
  methods: {
    ConsoleLog,
    setLastValue(lastValue: string) {
      ConsoleLog({ "FormRadioGroup.setLastValue": lastValue });
      this.lastValue = lastValue;
    },

    cssAnswer(itemValue: string, answer: string) {
      let cssClass = "margin-right-3 ";
      if (itemValue !== answer) {
        return cssClass;
      }
      switch (answer) {
        case RegularAnswer.Good:
          cssClass += "back-ground-green";
          break;
        case RegularAnswer.Defective:
          cssClass += "back-ground-red";
          break;
        case RegularAnswer.NA:
          cssClass += "back-ground-grey";
          break;
      }
      return cssClass;
    },
    cssLabel(itemValue: string, answer: string) {
      let cssClass = "font-size-14 margin-right-3 ";
      if (itemValue !== answer) {
        return cssClass;
      }
      switch (answer) {
        case RegularAnswer.Good:
        case RegularAnswer.Defective:
        case RegularAnswer.NA:
          cssClass += "color-white";
          break;
      }
      return cssClass;
    },
    onChanging(value): void {
      ConsoleLog({ "FormRegular.onChanging": value, lastValue: this.lastValue });
      // this.$emit("input", value);
      this.$emit("userChange", value, this.lastValue);
    },
    // onUserChanging(value: any) {
    //   this.$emit("FormRegular.userChange", value);
    // },
    editCick(e: any) {
      // ConsoleLog({ editCick: e });
      this.$emit("userEditClick", e);
    },
    questionCick(e: any) {
      // ConsoleLog({ questionCick: e });
      this.$emit("userQuestionClick", e);
    },
  },
  mounted(): void {
    const self = this as any;
    if (self.modelValue) {
      self.selectedValue = self.modelValue;
      this.lastValue = self.modelValue;
    }
    ConsoleLog({ "FormRadioGroup.lastValue": this.lastValue });
  },
});
</script>
