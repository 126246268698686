import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_textarea, {
        "model-value": _ctx.modelValue,
        rules: _ctx.rules,
        label: _ctx.label,
        readonly: _ctx.readonly,
        rows: _ctx.rows,
        variant: "underlined",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
        disabled: _ctx.disabled
      }, _createSlots({ _: 2 }, [
        (_ctx.required)
          ? {
              name: "label",
              fn: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "red-error" }, [
                  _createElementVNode("strong", null, "* ")
                ], -1)),
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["model-value", "rules", "label", "readonly", "rows", "disabled"])
    ]),
    _: 1
  }))
}