import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_zoom_in = _resolveComponent("zoom-in")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_img, {
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.zoom())),
                                    class: "div-align-center",
                                    src: _ctx.VehiclePath(_ctx.thumbnailName),
                                    height: _ctx.photoHeight,
                                    width: _ctx.photoWidth,
                                    onLoad: _ctx.loadedPic,
                                    ref: "photoThumb"
                                  }, null, 8, ["src", "height", "width", "onLoad"]),
                                  _createVNode(_component_zoom_in, {
                                    section: _ctx.recordQuestionHeadData,
                                    ref: "zoomIn"
                                  }, null, 8, ["section"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}