<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title
            :id="STR_SUBSECTION_ID + recordQuestionRegularData.subSectionId"
            >{{ recordQuestionRegularData.subSectionId }}</v-card-title
          >
          <!-- <v-card-text>abc</v-card-text> -->
          <!-- <v-card-text>{{recordOverviewItemData.title}}</v-card-text> -->
          <!-- {{ recordQuestionRegularData.sectionQuestionCollection }} -->
          <!-- <v-card-text class="padding-l-r-0"> -->
          <div>
            <template v-for="(question, index) in recordQuestionRegularData.questions" :key="index">
              <form-regular
                v-model="question.answer"
                :rules="rules.InputSelect"
                :label="question.desc"
                required
                :hardStop="question.hardStop"
                :items="RegularItems()"
                v-on:userChange="
                  (newValue, originalValue) =>
                    goToquestionRegularDetail(newValue, question, originalValue, index)
                "
                @userEditClick="(e) => editClick(e, question)"
                @userQuestionClick="(e) => questionClick(e, question)"
                :showEditIcon="question.notificationIsNeeded"
                :showQuestionIcon="!IsNullOrEmpty(question.instruction)"
                ref="regularQ"
              ></form-regular>
            </template>
          <!-- </v-card-text> -->
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
// import image from '../assets/image/PictureTractor.jpg'; // with import
import FormSelect from "@/components/FormSelect.vue";
import { PageName, RegularAnswer } from "@/models/enums";
import * as consts from "@/models/consts";
import FormRegular from "@/components/FormRegular.vue";
import { CloneObj, ConsoleLog, IsNullOrEmpty } from "@/utility";
import { Question } from "@/models/question-section/question";
import { StoreMutation } from "@/store/store-mapping";
import {
  GoDefect,
  GoGood,
  GetTranslationRequired,
  RegularItems,
} from "@/utility/ui-utility";

import { defineComponent } from 'vue';
export default defineComponent({
  components: { FormRegular },
  computed: {},
  props: {
    recordQuestionRegularData: {
      type: Object,
    },
  },
  data: () => ({
    rules: {
      inputString: [(v: string) => !!v || GetTranslationRequired()],
      inputNumber: [(v: string) => !!v || GetTranslationRequired()],
      InputSelect: [(v: any) => !!v || GetTranslationRequired()],
    },
    STR_SUBSECTION_ID: consts.STR_SUBSECTION_ID,
  }),
  methods: {
    RegularItems,
    IsNullOrEmpty,
    editClick(e: any, question: Question) {
      ConsoleLog({ HomeEditCick: e });
      GoDefect(this.$router, {
        questionData: question,
        lastValue: RegularAnswer.Defective,
      });
    },
    questionClick(e: any, question: Question) {
      ConsoleLog({ HomeQuestionCick: e });
      GoGood(this.$router, { questionData: question });
    },
    goToquestionRegularDetail(newValue, question, originalValue, index: number): void {
      const self = this as any;
      // const firstSet = IsNullOrEmpty(originalValue) ? true : false;
      ConsoleLog([
        "goToquestionRegularDetail",
        newValue,
        CloneObj(question),
        self.$refs.regularQ,
        index,
      ]);

      question.answer = newValue;
      self.$store.commit(StoreMutation.emUpdateQuestion, { question: question });
      if (newValue === RegularAnswer.Good) {
        self.$refs.regularQ[index].setLastValue(newValue);
      } else if (newValue === RegularAnswer.Defective) {
        GoDefect(this.$router, { questionData: question, lastValue: originalValue });
      } else if (newValue === RegularAnswer.NA) {
        self.$refs.regularQ[index].setLastValue(newValue);
      }
    },
  },
});
</script>
