import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_radio_group, {
        "model-value": _ctx.modelValue,
        inline: "",
        rules: _ctx.rules,
        label: _ctx.label,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
        readonly: _ctx.readonly
      }, _createSlots({
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_v_radio, {
              key: item.itemValue,
              label: item.itemText,
              value: item.itemValue
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 2
      }, [
        (_ctx.required)
          ? {
              name: "label",
              fn: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "red-error" }, [
                  _createElementVNode("strong", null, "* ")
                ], -1)),
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["model-value", "rules", "label", "readonly"])
    ]),
    _: 1
  }))
}