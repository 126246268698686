import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_form_regular = _resolveComponent("form-regular")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, {
                    id: _ctx.STR_SUBSECTION_ID + _ctx.recordQuestionRegularData.subSectionId
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.recordQuestionRegularData.subSectionId), 1)
                    ]),
                    _: 1
                  }, 8, ["id"]),
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recordQuestionRegularData.questions, (question, index) => {
                      return (_openBlock(), _createBlock(_component_form_regular, {
                        key: index,
                        modelValue: question.answer,
                        "onUpdate:modelValue": ($event: any) => ((question.answer) = $event),
                        rules: _ctx.rules.InputSelect,
                        label: question.desc,
                        required: "",
                        hardStop: question.hardStop,
                        items: _ctx.RegularItems(),
                        onUserChange: 
                  (newValue, originalValue) =>
                    _ctx.goToquestionRegularDetail(newValue, question, originalValue, index)
                ,
                        onUserEditClick: (e) => _ctx.editClick(e, question),
                        onUserQuestionClick: (e) => _ctx.questionClick(e, question),
                        showEditIcon: question.notificationIsNeeded,
                        showQuestionIcon: !_ctx.IsNullOrEmpty(question.instruction),
                        ref_for: true,
                        ref: "regularQ"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "label", "hardStop", "items", "onUserChange", "onUserEditClick", "onUserQuestionClick", "showEditIcon", "showQuestionIcon"]))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}