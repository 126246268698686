import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.question.desc), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("Introduction")
                        ])),
                        _: 1
                      })), [
                        [_directive_t, 'ml_114651']
                      ]),
                      _createElementVNode("p", null, _toDisplayString(this.question.instruction), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.GoBack(_ctx.$router))),
                        disabled: _ctx.btnDisable
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.okText), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}