import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_card, { justify: "center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showDialog,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event)),
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar, {
                dark: "",
                color: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    dark: "",
                    onClick: _ctx.close
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    icon: "",
                    dark: "",
                    onClick: _ctx.close
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_img, {
                src: _ctx.VehiclePath(_ctx.questions.imageName),
                ref: "vehiclePic",
                class: "div-align-center",
                height: _ctx.photoHeight,
                width: _ctx.photoWidth,
                onLoad: _ctx.loadedPic
              }, null, 8, ["src", "height", "width", "onLoad"]),
              _withDirectives(_createElementVNode("p", { class: "red-error" }, _toDisplayString(_ctx.errorMsg), 513), [
                [_vShow, _ctx.errorMsg.length > 0]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}