import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "red-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_form_autocomplete = _resolveComponent("form-autocomplete")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_qr_scaner = _resolveComponent("qr-scaner")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "text-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Search for the last eVCR")
                    ])),
                    _: 1
                  })), [
                    [_directive_t, 'ml_114663']
                  ]),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_actions, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_form_autocomplete, {
                            items: _ctx.vehicles(_ctx.SapVehcileCategory.Tractor),
                            modelValue: _ctx.$store.state.lastEvcrModule.tractor,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$store.state.lastEvcrModule.tractor) = $event)),
                            label: _ctx.Text('ml_114631', 'Tractor/Rigid/Truck'),
                            rules: _ctx.rules.tractor,
                            itemText: "vehicleId",
                            itemValue: "vehicleId",
                            userFilter: 
                  (item, queryText, itemText) =>
                    _ctx.onUserVehicleFilter(
                      item,
                      queryText,
                      itemText,
                      _ctx.SapVehcileCategory.Tractor
                    )
                ,
                            onUserChange: _cache[1] || (_cache[1] = 
                  (newValue) =>
                    _ctx.onVehicleChange(newValue, '', _ctx.SapVehcileCategory.Tractor)
                )
                          }, null, 8, ["items", "modelValue", "label", "rules", "userFilter"]),
                          _createVNode(_component_v_spacer),
                          _createVNode(_component_qr_scaner, {
                            formId: "formIdTractor",
                            initialValue: _ctx.$store.state.lastEvcrModule.tractor,
                            labelText: _ctx.Text('ml_114631', 'Tractor/Rigid/Truck'),
                            onHandleChange: _ctx.handleTractorChange
                          }, null, 8, ["initialValue", "labelText", "onHandleChange"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.validationMessage), 1),
                      _createVNode(_component_v_card_actions, null, {
                        default: _withCtx(() => [
                          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            color: "primary",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.GoBack(_ctx.$router)))
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode("Home")
                            ])),
                            _: 1
                          })), [
                            [_directive_t, 'ml_114649']
                          ]),
                          _createVNode(_component_v_spacer),
                          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            color: "primary",
                            onClick: _ctx.selectId
                          }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode("Next")
                            ])),
                            _: 1
                          }, 8, ["onClick"])), [
                            [_vShow, 
                  !_ctx.IsNullOrEmpty(this.$store.state.lastEvcrModule.tractor)
                ],
                            [_directive_t, 'ml_114639']
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}