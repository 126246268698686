<template>
  <v-row>
    <template v-if="showAsLabel">
      <v-card-text>
        <div v-text="label" />
        <div v-text="modelValue" />
      </v-card-text>
    </template>
    <template v-else>
      <!-- <v-text-field
        :value="value"
        :counter="counter"
        :rules="rules"
        :label="label"
        :readonly="readonly"
        v-on:input="onChanging"
        v-on:change="onUserChanging"
      > -->

      <v-autocomplete
        :model-value="modelValue"
        :disabled="readonly"
        :items="items"
        :filter="customFilter"
        :item-title="itemText"
        :item-value="itemValue"
        :rules="rules"
        :label="label"
        variant="underlined"
        v-on:update:modelValue="$emit('update:modelValue', $event)"
      >
        <template #label v-if="required">
          <span class="red-error"><strong>* </strong></span>{{ label }}
        </template>
      </v-autocomplete>
    </template>
  </v-row>
</template>

<script lang="ts">
import Vue from "vue";
//import { PropValidator } from "vue/types/options";
import { defineComponent } from 'vue';
export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    modelValue: String || Number,
    label: String,
    items: Array<any>,
    rules: Array<any>,
    itemText: { type: String, default: "itemText" },
    itemValue: { type: String, default: "itemValue" },
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    userFilter: {
      type: Function,
      default: (item: any, queryText: string, itemText: string) => {
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
      },
    } 
    //as PropValidator<(item: any, queryText: string, itemText: string) => boolean>,
  },
  computed: {},
  methods: {
    customFilter(item: any, queryText: string, itemText: string) {
      return this.userFilter(item, queryText, itemText);
    },
    // onChanging(value: string) {
    //   this.$emit("input", value);
    // },
    // onUserChanging(value: any) {
    //   this.$emit("userChange", value);
    // },
  },
});
</script>
