import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "red-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "text-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              (_ctx.isLoggedIn)
                ? (_openBlock(), _createBlock(_component_v_card_title, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_spacer),
                      _createElementVNode("a", {
                        href: _ctx.FuelHome()
                      }, [
                        _createVNode(_component_v_icon, {
                          "x-large": "",
                          color: "blue",
                          title: "Home"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.icons.mdiHomeCircle), 1)
                          ]),
                          _: 1
                        })
                      ], 8, _hoisted_1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.isLoggedIn)
                ? (_openBlock(), _createBlock(_component_v_card, { key: 1 }, {
                    default: _withCtx(() => [
                      (!_ctx.editable)
                        ? _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            color: "primary",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newEvcr())),
                            block: "",
                            disabled: _ctx.newDisable
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode(" New eVCR ")
                            ])),
                            _: 1
                          }, 8, ["disabled"])), [
                            [_directive_t, 'ml_114602']
                          ])
                        : _createCommentVNode("", true),
                      (_ctx.editable)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " "))
                        : _createCommentVNode("", true),
                      (_ctx.editable)
                        ? _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            key: 2,
                            color: "primary",
                            block: "",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goEdit())),
                            disabled: _ctx.editDisable
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode(" Edit Draft eVCR ")
                            ])),
                            _: 1
                          }, 8, ["disabled"])), [
                            [_directive_t, 'ml_114603']
                          ])
                        : _createCommentVNode("", true),
                      _cache[5] || (_cache[5] = _createElementVNode("div", null, " ", -1)),
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        block: "",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.GoSearch(_ctx.$router)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Text("ml_114604", "Display Last eVCR")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_v_card, { key: 2 }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("logging in is in progress...")
                        ])),
                        _: 1
                      })), [
                        [_directive_t, 'ml_114746']
                      ]),
                      (_ctx.loginError.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.loginError), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}