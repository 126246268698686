export const FIELD_REQUIRED = 'Field is required';
export const STR_ADDTIONAL_QUE = 'Additional Question';
export const STR_DEFAULT_LANG = 'en-US';
export const STR_LANG_ZH_CN = 'zh-CN';

export const STR_ITEM_TEXT = "itemText";
export const STR_ITEM_VALUE = "itemValue";
export const STR_BASE_64 = "BASE64";
export const STR_THIRD_PARTY_TRUE = "X";

export const SAP_QUESTION_TYPE_REGULAR = 'R'
export const SAP_QUESTION_TYPE_ADDTIONAL = 'T'

export const EVCR_DATA_KEY = "Storage_TmpEvcrData";
export const EVCR_IS_EDIT_KEY = "Storage_IsEdit";

export const STR_SUBSECTION_ID = "subSectionId";
export const STR_ADDITION_ID = "additionId";


// export const STR_TRIP_ID_MASK_LB = "NNNN-NNNNNN";
export const STR_TRIP_ID_MASK_LB = "XXXX-XXXXXX";
export const STR_TRIP_ID_PLACE_HOLDER_LB = "XXXX-XXXXXX";
// export const STR_TRIP_ID_MASK_PG = "NNNNNNNNNN";
export const STR_TRIP_ID_MASK_PG = "XXXXXXXXXX";
export const STR_TRIP_ID_PLACE_HOLDER_PG = "XXXXXXXXXX";
