<template>
  <v-row>
    <template v-if="showAsLabel">
      <v-card-text>
        <div v-text="label" />
        <div v-text="modelValue" />
      </v-card-text>
    </template>
    <template v-else>
      <v-text-field
        :model-value="modelValue"
        :counter="counter"
        :rules="rules"
        :label="label"
        :readonly="readonly"
        @update:modelValue="$emit('update:modelValue', $event)"
        :type="type"
        v-mask="mask"
        variant="underlined"
        density="default"
        :placeholder="placeholder.length > 0 ? placeholder : label"
      >
              <!-- v-on:change="onUserChanging" -->
        <template #label v-if="required">
          <span class="red-error"><strong>* </strong></span>{{ label }}
        </template>
      </v-text-field>
    </template>
  </v-row>
</template>

<script lang="ts">
import { ConsoleLog } from "@/utility";
import Vue from "vue";

import { defineComponent } from 'vue';
export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    // value: String || Number,
    modelValue: String || Number,
    label: String,
    counter: Number,
    rules: Array<any>,
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    mask: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    type: { type: String, default: "text" },
  },
  computed: {},
  methods: {
    // onChanging(value: string) {
    //   ConsoleLog({ "FormInput.onChanging": value });
    //   this.$emit("input", value);
    //   this.$emit("userChange", value);
    // },
    // onUserChanging(value: any) {
    //   ConsoleLog({ "FormInput.onUserChanging": value });
    //   this.$emit("userChange", value);
    // },
  },
});
</script>
