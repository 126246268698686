import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "red-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecordQuestionHead = _resolveComponent("RecordQuestionHead")!
  const _component_RecordQuestionRegular = _resolveComponent("RecordQuestionRegular")!
  const _component_RecordQuestionAdditional = _resolveComponent("RecordQuestionAdditional")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, {
                    ref: "form",
                    modelValue: _ctx.valid,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.valid) = $event)),
                    "lazy-validation": ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        false
                          ? (_openBlock(), _createBlock(_component_RecordQuestionHead, {
                              key: 0,
                              "record-question-head-data": _ctx.questions,
                              "thumbnail-name": _ctx.questions.thumbnailName
                            }, null, 8, ["record-question-head-data", "thumbnail-name"]))
                          : _createCommentVNode("", true),
                        (_ctx.isRegular)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.getData, (sectionData, sectionIndex) => {
                              return _withDirectives((_openBlock(), _createBlock(_component_RecordQuestionRegular, {
                                key: sectionIndex,
                                "record-question-regular-data": sectionData
                              }, null, 8, ["record-question-regular-data"])), [
                                [_vShow, _ctx.isRegular]
                              ])
                            }), 128))
                          : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.getData, (sectionData, sectionIndex) => {
                              return (_openBlock(), _createBlock(_component_RecordQuestionAdditional, {
                                key: sectionIndex + 10000,
                                "record-question-additional-data": sectionData
                              }, null, 8, ["record-question-additional-data"]))
                            }), 128))
                      ]),
                      (_ctx.errorMsg.length > 0)
                        ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.errorMsg), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_card_actions, null, {
                        default: _withCtx(() => [
                          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            color: "primary",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.GoOverview(_ctx.$router)))
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode(" eVCR Overview ")
                            ])),
                            _: 1
                          })), [
                            [_directive_t, 'ml_114646']
                          ]),
                          _createVNode(_component_v_spacer),
                          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            color: "primary",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goPrevious())),
                            disabled: !_ctx.notFirst
                          }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode(" Page Up ")
                            ])),
                            _: 1
                          }, 8, ["disabled"])), [
                            [_directive_t, 'ml_114750']
                          ]),
                          _createVNode(_component_v_spacer),
                          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            color: "primary",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goNext())),
                            disabled: !_ctx.notLast
                          }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode(" Page Down ")
                            ])),
                            _: 1
                          }, 8, ["disabled"])), [
                            [_directive_t, 'ml_114751']
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_actions)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}