<template>
  <v-row>
    <v-textarea
      :model-value="modelValue"
      :rules="rules"
      :label="label"
      :readonly="readonly"
      :rows="rows"
      variant="underlined"
      v-on:update:modelValue="$emit('update:modelValue', $event)"
      :disabled="disabled"
    >
      <template #label v-if="required">
        <span class="red-error"><strong>* </strong></span>{{ label }}
      </template>
    </v-textarea>
  </v-row>
</template>

<script lang="ts">
import Vue from "vue";

import { defineComponent } from 'vue';
export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    // value: String,
    modelValue: String,
    label: String,
    rules: Array<any>,
    rows: String || Number,
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {},
  methods: {
    // onChanging(value: string) {
    //   this.$emit("input", value);
    //   this.$emit("userChange", value);
    // },
    // onUserChanging(value: any) {
    //   this.$emit("userChange", value);
    // },
  },
});
</script>
