import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      (_ctx.showAsLabel)
        ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                textContent: _toDisplayString(_ctx.label)
              }, null, 8, _hoisted_1),
              _createElementVNode("div", {
                textContent: _toDisplayString(_ctx.modelValue)
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_v_autocomplete, {
            key: 1,
            "model-value": _ctx.modelValue,
            disabled: _ctx.readonly,
            items: _ctx.items,
            filter: _ctx.customFilter,
            "item-title": _ctx.itemText,
            "item-value": _ctx.itemValue,
            rules: _ctx.rules,
            label: _ctx.label,
            variant: "underlined",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
          }, _createSlots({ _: 2 }, [
            (_ctx.required)
              ? {
                  name: "label",
                  fn: _withCtx(() => [
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "red-error" }, [
                      _createElementVNode("strong", null, "* ")
                    ], -1)),
                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["model-value", "disabled", "items", "filter", "item-title", "item-value", "rules", "label"]))
    ]),
    _: 1
  }))
}