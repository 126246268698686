import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_form_textarea = _resolveComponent("form-textarea")!
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_regular = _resolveComponent("form-regular")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          (_ctx.recordQuestionAdditionalData.questions.length > 0)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, {
                        id: _ctx.STR_SUBSECTION_ID + _ctx.STR_ADDITION_ID
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.recordQuestionAdditionalData.subSectionTitle), 1)
                        ]),
                        _: 1
                      }, 8, ["id"]),
                      _createVNode(_component_v_card_text, { class: "padding-l-r-0" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recordQuestionAdditionalData.questions, (question, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (question.questionType === _ctx.QuestionType.Char)
                                ? (_openBlock(), _createBlock(_component_form_textarea, {
                                    key: index,
                                    modelValue: question.answer,
                                    "onUpdate:modelValue": [($event: any) => ((question.answer) = $event), ($event: any) => (_ctx.onUpdateCharQuestion(question))],
                                    rules: _ctx.rules.inputString,
                                    label: question.desc,
                                    rows: "3",
                                    required: ""
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "label"]))
                                : (question.questionType === _ctx.QuestionType.Number)
                                  ? (_openBlock(), _createBlock(_component_form_input, {
                                      key: index,
                                      modelValue: question.answer,
                                      "onUpdate:modelValue": [($event: any) => ((question.answer) = $event), (newValue) => _ctx.onUpdateQuestion(newValue, question)],
                                      rules: _ctx.rules.inputNumber,
                                      label: question.desc,
                                      required: ""
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "label"]))
                                  : (
                    question.questionType === _ctx.QuestionType.Boolean &&
                    question.questionTypeBooleanValue
                  )
                                    ? (_openBlock(), _createBlock(_component_form_regular, {
                                        key: index,
                                        modelValue: question.answer,
                                        "onUpdate:modelValue": ($event: any) => ((question.answer) = $event),
                                        rules: _ctx.rules.InputSelect,
                                        label: question.desc,
                                        required: "",
                                        items: _ctx.RegularItems(),
                                        onUserChange: 
                      (newValue, originalValue) =>
                        _ctx.goToquestionRegularDetail(
                          newValue,
                          question,
                          originalValue,
                          question.sequenceId
                        )
                    ,
                                        onUserEditClick: (e) => _ctx.editClick(e, question),
                                        onUserQuestionClick: (e) => _ctx.questionClick(e, question),
                                        showEditIcon: question.notificationIsNeeded,
                                        showQuestionIcon: !_ctx.IsNullOrEmpty(question.instruction),
                                        ref_for: true,
                                        ref: 'regularQ' + question.sequenceId
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "label", "items", "onUserChange", "onUserEditClick", "onUserQuestionClick", "showEditIcon", "showQuestionIcon"]))
                                    : _createCommentVNode("", true)
                            ], 64))
                          }), 256))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}