import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_ctx.recordQuestionSummaryRegularItemData.notificationIsNeeded)
    ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.recordQuestionSummaryRegularItemData.desc), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.recordQuestionSummaryRegularItemData.issueDesc), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recordQuestionSummaryRegularItemData.attachments.filter(
        (item) => !_ctx.IsNullOrEmpty(item.attachmentBase64)
      ), (attachment, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              _createVNode(_component_v_img, {
                src: _ctx.AddBase64Head(attachment.attachmentBase64),
                alt: 'attchment for' + _ctx.recordQuestionSummaryRegularItemData.desc,
                title: 'attchment for' + _ctx.recordQuestionSummaryRegularItemData.desc,
                contain: true
              }, null, 8, ["src", "alt", "title"]),
              _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1))
            ], 64))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}