import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_form_textarea = _resolveComponent("form-textarea")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_form_file = _resolveComponent("form-file")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, {
                    ref: "form",
                    modelValue: _ctx.valid,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.valid) = $event)),
                    "lazy-validation": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(this.question.desc), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          (!_ctx.IsNullOrEmpty(_ctx.question.instruction))
                            ? _withDirectives((_openBlock(), _createBlock(_component_v_card_title, { key: 0 }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode("Instruction")
                                ])),
                                _: 1
                              })), [
                                [_directive_t, 'ml_114651']
                              ])
                            : _createCommentVNode("", true),
                          (!_ctx.IsNullOrEmpty(_ctx.question.instruction))
                            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(this.question.instruction), 1))
                            : _createCommentVNode("", true),
                          _createVNode(_component_form_textarea, {
                            modelValue: _ctx.question.issueDesc,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.question.issueDesc) = $event)),
                            rules: _ctx.rules.issueDesc,
                            label: _ctx.Text('ml_114652', 'Description'),
                            required: "",
                            rows: "3"
                          }, null, 8, ["modelValue", "rules", "label"])
                        ]),
                        _: 1
                      }),
                      (_ctx.whetherHidePictureUpload)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.Text('ml_116413', "No picture needed for 3rd party defect")), 1))
                        : _createCommentVNode("", true),
                      (!_ctx.whetherHidePictureUpload)
                        ? (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_form_file, {
                                modelValue: _ctx.question.attachments[0].attachment,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.question.attachments[0].attachment) = $event)),
                                label: _ctx.Text('ml_114653', 'Attachment'),
                                initalImgBase64: 
                  _ctx.AddBase64Head(_ctx.question.attachments[0].attachmentBase64)
                ,
                                onUserChange: _cache[2] || (_cache[2] = 
                  (file, picBase64) => _ctx.uploadAttachment(file, picBase64, 0)
                )
                              }, null, 8, ["modelValue", "label", "initalImgBase64"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.whetherHidePictureUpload)
                        ? (_openBlock(), _createBlock(_component_v_card_text, { key: 2 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_form_file, {
                                modelValue: _ctx.question.attachments[1].attachment,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.question.attachments[1].attachment) = $event)),
                                label: _ctx.Text('ml_114653', 'Attachment'),
                                initalImgBase64: 
                  _ctx.AddBase64Head(_ctx.question.attachments[1].attachmentBase64)
                ,
                                onUserChange: _cache[4] || (_cache[4] = 
                  (file, picBase64) => _ctx.uploadAttachment(file, picBase64, 1)
                )
                              }, null, 8, ["modelValue", "label", "initalImgBase64"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.whetherHidePictureUpload)
                        ? (_openBlock(), _createBlock(_component_v_card_text, { key: 3 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_form_file, {
                                modelValue: _ctx.question.attachments[2].attachment,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.question.attachments[2].attachment) = $event)),
                                label: _ctx.Text('ml_114653', 'Attachment'),
                                initalImgBase64: 
                  _ctx.AddBase64Head(_ctx.question.attachments[2].attachmentBase64)
                ,
                                onUserChange: _cache[6] || (_cache[6] = 
                  (file, picBase64) => _ctx.uploadAttachment(file, picBase64, 2)
                )
                              }, null, 8, ["modelValue", "label", "initalImgBase64"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_card_actions, null, {
                        default: _withCtx(() => [
                          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            color: "primary",
                            onClick: _ctx.onCancel
                          }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode(" Cancel ")
                            ])),
                            _: 1
                          }, 8, ["onClick"])), [
                            [_directive_t, 'ml_114660']
                          ]),
                          _createVNode(_component_v_spacer),
                          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            color: "primary",
                            onClick: _ctx.onOkay
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode(" OK ")
                            ])),
                            _: 1
                          }, 8, ["onClick"])), [
                            [_directive_t, 'ml_114654']
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}