import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 0,
  id: "ap-loading-overlay"
}
const _hoisted_3 = { id: "ap-footer" }
const _hoisted_4 = {
  key: 0,
  class: "red-error"
}
const _hoisted_5 = { id: "ap-copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.$store.state.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { id: "ap-loading-image" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createVNode(_component_v_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_main, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { fluid: "" }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_component_router_view, {
                  key: _ctx.$route.fullPath
                })),
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.loginError.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.loginError), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_5, " Copyright © 1996 - " + _toDisplayString(new Date().getFullYear()) + " Air Products and Chemicals, Inc " + _toDisplayString(_ctx.version), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}