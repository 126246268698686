import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "red-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_file_input, {
        modelValue: _ctx.fileValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fileValue) = $event)),
        "truncate-length": "30",
        accept: "image/*",
        placeholder: _ctx.placeHolder,
        onChange: _ctx.onUserChanging,
        rules: _ctx.rules,
        multiple: _ctx.multiple,
        capture: "environment"
      }, _createSlots({ _: 2 }, [
        (_ctx.required)
          ? {
              name: "label",
              fn: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "red-error" }, [
                  _createElementVNode("strong", null, "* ")
                ], -1)),
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["modelValue", "placeholder", "onChange", "rules", "multiple"]),
      (_ctx.errorMsg.length > 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.errorMsg), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_v_img, {
        src: _ctx.imgBase64,
        ref: "uploadedPic"
      }, null, 8, ["src"])
    ]),
    _: 1
  }))
}