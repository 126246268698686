<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title v-t="'ml_114667'">Trip Info of Last eVCR</v-card-title>
          <v-card-text>
            <form-radio-group
              v-model="tripInfo.tripType"
              :label="Text('ml_114897', 'Trip Type')"
              :input="tripInfo.tripType"
              :items="tripInfo.tripTypeItems"
              readonly
            ></form-radio-group>

            <form-input v-model="tripInfo.lbsTripId" :label="tripIdLabel" readonly>
            </form-input>
            <form-radio-group
              v-model="tripInfo.prePost"
              :label="Text('ml_114627', 'Pre/Post')"
              :input="tripInfo.prePost"
              :items="tripInfo.prePostItems"
              readonly
            ></form-radio-group>
            <!-- <v-card-actions class="v-card__actions_padding_left0"> -->
            <form-select
              :items="getPlantItems()"
              v-model="tripInfo.plantId"
              :label="Text('ml_114628', 'Plant ID')"
               v-if="false"
              readonly
            ></form-select>
            <!-- </v-card-actions> -->
            <form-input
              v-model="tripInfo.driver1Name"
              :label="Text('ml_114629', 'Driver')"
              readonly
            >
            </form-input>
            <!-- <form-input
              v-model="tripInfo.driver2"
              :label="Text('ml_114630', 'Driver 2')"
              readonly
            >
            </form-input> -->
            <v-card-actions class="v-card__actions_padding_left0">
              <form-input
                v-model="tripInfo.tractor"
                readonly
                :label="Text('ml_114631', 'Tractor/Rigid/Truck')"
              ></form-input>
            </v-card-actions>
            <!-- <v-checkbox
              v-model="tripInfo.thirdParty"
              :label="`3rd Party Tractor`"
              readonly
            ></v-checkbox> -->
            <v-card-actions class="v-card__actions_padding_left0">
              <form-input
                v-model="tripInfo.chassis"
                :label="Text('ml_114633', 'Chassis')"
                readonly
              ></form-input>
            </v-card-actions>
            <v-card-actions class="v-card__actions_padding_left0">
              <form-input
                v-model="tripInfo.vessel"
                :label="Text('ml_114634', 'Vessel')"
                readonly
              ></form-input>
            </v-card-actions>
            <form-input
              v-model="tripInfo.odometer"
              :label="Text('ml_114635', 'Odometer')"
              readonly
            ></form-input>
            <form-input
              v-model="localDateTime.date"
              :label="Text('ml_114636', 'Date')"
              readonly
            ></form-input>
            <form-input
              v-model="localDateTime.time"
              :label="Text('ml_114637', 'Time')"
              readonly
            ></form-input>
            <p v-if="errorMsg.length > 0" class="red-error">{{ errorMsg }}</p>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title v-t="'ml_114668'">eVCR Questions/Answers</v-card-title>
          <v-card-text>
            <template v-for="(question, index) in questions" :key="index">
              <form-input                
                v-model="question.answer"
                :label="question.desc"
                :readonly="true"
                :showAsLabel="false"
              ></form-input>

              <!-- <p :key="index + 100" v-if="question.notificationIsNeeded">
                {{ Text("ml_114904", "Defect Description") }}:
              </p> -->
              <p :key="index + 300" v-if="question.notificationIsNeeded">
                {{ question.comment }}
              </p>
              <!-- <template
                v-if="
                  question.notificationIsNeeded && question.attachmentBase64.length > 0
                "
              >
                <v-img
                  :key="index + 100000"
                  :src="AddBase64Head(question.attachmentBase64)"
                  :alt="question.desc"
                  :title="question.desc"
                />
              </template> -->
            </template>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="GoBack($router)" color="primary" v-t="'ml_114638'">
                Back
              </v-btn>
              <!-- <v-spacer></v-spacer>
              <v-btn color="primary" @click="GoHome($router)" v-t="'ml_114649'">
                Home
              </v-btn> -->
              <!-- <v-spacer></v-spacer>
              <v-btn color="primary" @click="onNext()" v-if="this.questions.length > 0" v-t="'ml_114639'">
                Next
              </v-btn> -->
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import {
  CloneObj,
  ConsoleLog,
  IsDebug,
  Timeout,
  validateOdometer,
  PadLeftZero,
  Text,
} from "@/utility";
import * as consts from "@/models/consts";
import { StoreAction, StoreGetter, StoreMutation } from "@/store/store-mapping";
import { mapGetters } from "vuex";
import { AddBase64Head, RemoveBase64Head } from "@/utility/ui-utility";

import FormInput from "@/components/FormInput.vue";
import { ISelectItem, SelectItem } from "@/models/select-item";
import FormRadioGroup from "@/components/FormRadioGroup.vue";
import FormSelect from "@/components/FormSelect.vue";
import {
  GoHome,
  GoQuestionTractor,
  GoTripInfo,
  GoCondition,
  GoOverview,
  GoBack,
  GoQuestionsView,
} from "@/utility/ui-utility";
import { IPlant } from "@/models/plant";
import { NotificationFilter } from "@/models/maitain-notification";
import { ValidateEvcr } from "@/services/data-process/basic-process";
import { TripType } from "@/models/enums";
import { defineComponent } from 'vue';
export default defineComponent({
  components: { FormInput, FormRadioGroup, FormSelect },
  data() {
    return {
      errorMsg: "",
      valid: false,
      tripIdLabel: Text("ml_114625", "LBShell Trip ID"),
    };
  },
  computed: {
    ...mapGetters({
      tripInfo: StoreGetter.lemLastEvcrTripInfo,
      questions: StoreGetter.lemQuestions,
    }),
    localDateTime(): { date: string; time: string; } {
      // const date3 = new Date('2023-06-20T09:21:00Z');
      // transformed by web UI code
      // this.tripInfo.date 2023-06-20
      // this.tripInfo.date 09:21
      ConsoleLog({ 'lastEvcr.date': this.tripInfo.date });
      ConsoleLog({ 'lastEvcr.time': this.tripInfo.time });
      const localDateTime = new Date( this.tripInfo.date + 'T' + this.tripInfo.time + ':00Z' );
      const localDateString = localDateTime.getFullYear() + '-' + PadLeftZero(localDateTime.getMonth() + 1) + '-' + PadLeftZero(localDateTime.getDate());
      const localTimeString = PadLeftZero(localDateTime.getHours()) + ':' + PadLeftZero(localDateTime.getMinutes());
      ConsoleLog({ 'lastEvcr.local.date': localDateString });
      ConsoleLog({ 'lastEvcr.local.time': localTimeString });
      return { date: localDateString, time: localTimeString };
    },    
  },
  methods: {
    Text,
    getPlantItems() {
      const items: ISelectItem[] = [];
      items.push(new SelectItem(`${this.tripInfo.plantId}`, this.tripInfo.plantId));
      return items;
    },
    GoBack,
    GoHome,
    IsDebug,
    AddBase64Head,
    async initialize() {
      // const self = this as any;
      // await self.$store.dispatch(StoreAction.emInitialData, {
      //   longitude: this.customerLocation.longitude,
      //   latitude: this.customerLocation.latitude,
      // });
      if (this.tripInfo.tripType === TripType.PG) {
        this.tripIdLabel = Text("ml_114902", "PG Trip ID");
      }
    },
    async onNext(): Promise<void> {
      this.errorMsg = "";
      const hasQuestions = this.questions.length > 0;
      if (hasQuestions) {
        GoQuestionsView(this.$router);
      }
    },
  },
  async created() {},
  async beforeMount() {
    await this.initialize();
  },
});
</script>

<style></style>
