import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions.regularQuestionCollection, (subSection, index) => {
        return (_openBlock(), _createBlock(_component_v_btn, {
          key: index,
          class: _normalizeClass(_ctx.SubSectionStyle(subSection)),
          to: _ctx.GetQueSectionUrl(subSection.subSectionId)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(subSection.subSectionId), 1)
          ]),
          _: 2
        }, 1032, ["class", "to"]))
      }), 128)),
      (_ctx.questions.containAdditionalQuestion)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            to: _ctx.GetQueSectionUrl(_ctx.STR_ADDITION_ID),
            class: _normalizeClass(_ctx.SubSectionStyle(_ctx.questions.additionalQuestionCollection[0]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("mdi-format-list-bulleted-square")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["to", "class"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}