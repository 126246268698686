import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "red-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_form_radio_group = _resolveComponent("form-radio-group")!
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_select = _resolveComponent("form-select")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("Trip Info of Last eVCR")
                    ])),
                    _: 1
                  })), [
                    [_directive_t, 'ml_114667']
                  ]),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_form_radio_group, {
                        modelValue: _ctx.tripInfo.tripType,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tripInfo.tripType) = $event)),
                        label: _ctx.Text('ml_114897', 'Trip Type'),
                        input: _ctx.tripInfo.tripType,
                        items: _ctx.tripInfo.tripTypeItems,
                        readonly: ""
                      }, null, 8, ["modelValue", "label", "input", "items"]),
                      _createVNode(_component_form_input, {
                        modelValue: _ctx.tripInfo.lbsTripId,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tripInfo.lbsTripId) = $event)),
                        label: _ctx.tripIdLabel,
                        readonly: ""
                      }, null, 8, ["modelValue", "label"]),
                      _createVNode(_component_form_radio_group, {
                        modelValue: _ctx.tripInfo.prePost,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tripInfo.prePost) = $event)),
                        label: _ctx.Text('ml_114627', 'Pre/Post'),
                        input: _ctx.tripInfo.prePost,
                        items: _ctx.tripInfo.prePostItems,
                        readonly: ""
                      }, null, 8, ["modelValue", "label", "input", "items"]),
                      false
                        ? (_openBlock(), _createBlock(_component_form_select, {
                            key: 0,
                            items: _ctx.getPlantItems(),
                            modelValue: _ctx.tripInfo.plantId,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tripInfo.plantId) = $event)),
                            label: _ctx.Text('ml_114628', 'Plant ID'),
                            readonly: ""
                          }, null, 8, ["items", "modelValue", "label"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_form_input, {
                        modelValue: _ctx.tripInfo.driver1Name,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tripInfo.driver1Name) = $event)),
                        label: _ctx.Text('ml_114629', 'Driver'),
                        readonly: ""
                      }, null, 8, ["modelValue", "label"]),
                      _createVNode(_component_v_card_actions, { class: "v-card__actions_padding_left0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_form_input, {
                            modelValue: _ctx.tripInfo.tractor,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tripInfo.tractor) = $event)),
                            readonly: "",
                            label: _ctx.Text('ml_114631', 'Tractor/Rigid/Truck')
                          }, null, 8, ["modelValue", "label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_actions, { class: "v-card__actions_padding_left0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_form_input, {
                            modelValue: _ctx.tripInfo.chassis,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tripInfo.chassis) = $event)),
                            label: _ctx.Text('ml_114633', 'Chassis'),
                            readonly: ""
                          }, null, 8, ["modelValue", "label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_actions, { class: "v-card__actions_padding_left0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_form_input, {
                            modelValue: _ctx.tripInfo.vessel,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.tripInfo.vessel) = $event)),
                            label: _ctx.Text('ml_114634', 'Vessel'),
                            readonly: ""
                          }, null, 8, ["modelValue", "label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_form_input, {
                        modelValue: _ctx.tripInfo.odometer,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.tripInfo.odometer) = $event)),
                        label: _ctx.Text('ml_114635', 'Odometer'),
                        readonly: ""
                      }, null, 8, ["modelValue", "label"]),
                      _createVNode(_component_form_input, {
                        modelValue: _ctx.localDateTime.date,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localDateTime.date) = $event)),
                        label: _ctx.Text('ml_114636', 'Date'),
                        readonly: ""
                      }, null, 8, ["modelValue", "label"]),
                      _createVNode(_component_form_input, {
                        modelValue: _ctx.localDateTime.time,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localDateTime.time) = $event)),
                        label: _ctx.Text('ml_114637', 'Time'),
                        readonly: ""
                      }, null, 8, ["modelValue", "label"]),
                      (_ctx.errorMsg.length > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.errorMsg), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode("eVCR Questions/Answers")
                    ])),
                    _: 1
                  })), [
                    [_directive_t, 'ml_114668']
                  ]),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (question, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          _createVNode(_component_form_input, {
                            modelValue: question.answer,
                            "onUpdate:modelValue": ($event: any) => ((question.answer) = $event),
                            label: question.desc,
                            readonly: true,
                            showAsLabel: false
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                          (question.notificationIsNeeded)
                            ? (_openBlock(), _createElementBlock("p", {
                                key: index + 300
                              }, _toDisplayString(question.comment), 1))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128)),
                      _createVNode(_component_v_card_actions, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_spacer),
                          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.GoBack(_ctx.$router))),
                            color: "primary"
                          }, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode(" Back ")
                            ])),
                            _: 1
                          })), [
                            [_directive_t, 'ml_114638']
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}